import React from "react";
import "./field.scss";
import { IDefaultProps } from "interfaces/IDefaultProps";
import Label from "components/common/label/Label"

interface IProps extends IDefaultProps {
  boxPadding?: boolean;
  icon?: any;
  label?: any;
  content?: any;
  labelSize?: string;
  borderBox?: boolean;
  spacing?: boolean;
  isVisible?: boolean;
}

const Field = (props: IProps) => {
//(props.isVisible === undefined ? true : props.isVisible)
  return (
    <div className={"field" + (props.isVisible || props.isVisible === undefined ? " visible" : " hidden")}>
      <div className={"fieldheader"}>
        <div className={"icon"}>
        {props.icon}
        </div>
        <Label name={props.label} bold={true} labelSize={props.labelSize}/>
      </div>
      <div className={"box" + (props.borderBox === true ? " border" : "") + (props.spacing === true ? " spacing" : "")}>
        <div className={(props.boxPadding === true ? "padding" : "")}>
        {props.content}
        </div>
      </div>
    </div>
  );
};

export default Field;
