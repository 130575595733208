import React, { useEffect, useState } from "react";
import moment from 'moment'
import { timeTable } from "config/conf";
import { smartConnect } from "app/appUtils";
import { ISmartState, ISmartAction } from "interfaces/ISmart";
import Popup from "components/common/popup/Popup";
import { IPopupProps } from "components/common/popup/IPopupProps";
import { useSmartForm, smartValidationSchema, smartStringValidator } from "hooks/useSmartForm";
import { useRoute } from "hooks/useRoute";

import Form from "components/common/form/Form";

import { nameOf } from "utils/nameOfUtils";
import Input from "components/common/input/Input";
import RadioGroup from "components/common/radiogroup/Radiogroup";
import Phone from "components/common/phone/Phone";
import TextArea from "components/common/textArea/TextArea";
import Field from "components/common/field/Field"
import Row from "components/common/row/Row"
import { replaceTags } from "utils/stringUtils";

import { interventionPointModule, interventionDateModule, availablePromotersModule, animationModule} from "config/modulesConf";
import { IAllInterventionPointsResult } from "containers/interventionPoints/IInterventionPoint";
import { INewAnimation, IMissionDayItem } from "containers/animations/IAnimations";
import { getInterventionPointPromotersAvailableApi, createAnimationApi } from "config/apiConf";

import { IValue } from "interfaces/common/IValue";
import DayAgenda from "components/calendar/dayagenda/DayAgenda";
import NeuroButton from "components/buttons/neuroButton/NeuroButton"
import { FaClock, FaUserAlt, FaInfo } from "react-icons/fa";

interface IProps extends IPopupProps {
  interventionDate?: ISmartState;
  interventionPoint?: ISmartState;
  availablePromoters?: ISmartState;
  availablePromotersActions?: ISmartAction;
  animation?: ISmartState;
  animationActions?: ISmartAction;
}

const NewAnimationPopup = (props: IProps) => {
  const { addParams } = useRoute();
  const [loader, setLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const interventionPoint: IAllInterventionPointsResult = props.interventionPoint?.data;
  const interventionDate: Date = props.interventionDate?.data;
  const availablePromoters: IValue[] = props.availablePromoters?.data;
  const [timeSlot , setTimeSlot] = useState() as any;
  const [promoter , setPromoter] = useState() as any;
  const [disabledBtns, setDisabledBtns] = useState(true);

  useEffect(() => {
    props.availablePromotersActions?.reset();
    props.animationActions?.reset();

    const filter: any = { interventionPointId: interventionPoint.id, date: moment(interventionDate).format('YYYY-MM-DD') };
    props.availablePromotersActions?.httpCall(addParams(getInterventionPointPromotersAvailableApi, filter));
    return () => {
      props.availablePromotersActions?.reset();
      props.animationActions?.reset();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.animation?.opCompleted || props.animation?.errors) {
      setBtnLoader(false);
    }
    // eslint-disable-next-line
  }, [props.animation?.opCompleted || props.animation?.errors]);

  useEffect(() => {
    if (props.availablePromoters?.opCompleted) {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [props.availablePromoters?.opCompleted]);

  useEffect(() => {
    if (props.availablePromoters?.errors) {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [props.availablePromoters?.errors]);

  const onPromoterChange = (val: IValue) => {
    setPromoter(val);
    missionDayTimeSlotsChange(val, timeSlot);
  };

  const timeSlotsChange = (val: IValue) => {
    setTimeSlot(val);
    missionDayTimeSlotsChange(promoter, val);
  };

  const missionDayTimeSlotsChange = (promoter: any, timeSlot: any) => {
    if (!promoter || !timeSlot) return;

    var missionDays : IMissionDayItem[] = [{
      date : moment.utc(interventionDate).toDate(),
      timeSlots : [{
        promoterId: promoter?.id,
        startTime: moment.utc(moment.duration(timeSlot.object[0]).asMilliseconds()).format("HH:mm:ss"),
        endTime: moment.utc(moment.duration(timeSlot.object[1]).asMilliseconds()).format("HH:mm:ss"),
        interventionPointId : interventionPoint.id
      },
      {
        promoterId: promoter?.id,
        startTime: moment.utc(moment.duration(timeSlot.object[2]).asMilliseconds()).format("HH:mm:ss"),
        endTime: moment.utc(moment.duration(timeSlot.object[3]).asMilliseconds()).format("HH:mm:ss"),
        interventionPointId : interventionPoint.id
      }]
    }];

    smartForm.change({
      target: {
        name: "missionDays",
        value: missionDays,
      },
    });

    setDisabledBtns(false);
  }

  const create = (values: INewAnimation) => {
    setLoader(true);

    props.animationActions?.httpCall(createAnimationApi, values);
    setLoader(false);
  };

  const smartForm = useSmartForm({
    initialValues: {
      name: "BatMission",
      description: "BatMission",
      interventionPointId: interventionPoint?.id,
      email: interventionPoint?.email,
      telephone: interventionPoint?.telephone,
      comment: interventionPoint?.comment,
    },
    validationSchema: smartValidationSchema({
      email: smartStringValidator()
        .email("l'email est invalide")
        .required("L'email est requis")
        .max(320, replaceTags("Erreur longueur max. Doit contenir au maximum #1 caractères.", 320)),
    }),
    onSubmit: (values: any) => {
      setBtnLoader(true);
      create(values);
      setDisabledBtns(true);
    },
    onChanged: () => {
    },
  });

  const disabled = false;
  const email = nameOf((n: INewAnimation) => n.email);
  const telephone = nameOf((n: INewAnimation) => n.telephone);
  const comment = nameOf((n: INewAnimation) => n.comment);

  return (
    <Popup {...props} bigSize={true} title={`${interventionPoint?.codePOS} | ${interventionPoint?.name}`} scrollableContent={true}>
      <Form scrollable={true}
        loader={loader}
        fullHeight={true}
        actions={[
          <NeuroButton type='submit' loader={btnLoader} color="green" size={'normal'} disabled={disabledBtns} label={"Valider"} onClick={smartForm.submit}  />
        ]}>

        <div className="center">
          <DayAgenda date={interventionDate}/>
        </div>

        <Field label="Horaires" labelSize='medium' 
        icon={<FaClock size={20}/>}
        content={
            <RadioGroup
              key='Schedules'
              color="orange"
              values={timeTable}
              onChange={timeSlotsChange}/>
        }/>

        <Field label="Intervenants" labelSize='medium' 
        icon={<FaUserAlt size={20}/>}
        content={
          <RadioGroup
          key='Promoters'
          color="blue"
          values={availablePromoters}
          onChange={onPromoterChange}
        /> }/>

        <Field label="Point d'intervention" labelSize='medium' spacing={true}
        icon={<FaInfo size={20}/>}
        content={
        <Row fullSize={true} center={true}>
            <Input
              required={true}
              disabled={disabled}
              value={smartForm.getValue(email)}
              name={email}
              error={smartForm.getError(email)}
              onNativeChange={smartForm.change}
              label={"Email"}/>
            <Phone
              disabled={disabled}
              value={smartForm.getValue(telephone)}
              name={telephone}
              error={smartForm.getError(telephone)}
              onNativeChange={smartForm.change}
              label={"Téléphone"}/>
        </Row>
        }/>
        <TextArea fullSize={true} center={true}
              disabled={disabled}
              value={smartForm.getValue(comment)}
              name={comment}
              error={smartForm.getError(comment)}
              onNativeChange={smartForm.change}
              label={"Commentaire"}/>
      </Form>
    </Popup>
  );
};

export default smartConnect([
    interventionPointModule, interventionDateModule, availablePromotersModule, animationModule
  ], [interventionPointModule, interventionDateModule, availablePromotersModule, animationModule])(NewAnimationPopup);
