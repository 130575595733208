import React, { useEffect, useState } from "react";
import { deepClone } from "utils/cloneUtils";
import { nameOf } from "utils/nameOfUtils";
import { getArrayKeysFromValue } from "utils/objectUtils";
import { calculatePageFilters } from "utils/pageUtils";
import { useChecks } from "hooks/useChecks";
import { useRoute } from "hooks/useRoute";

import { ISmartAction, ISmartState } from "interfaces/ISmart";

import { smartConnect } from "app/appUtils";
import { IPopupProps } from "components/common/popup/IPopupProps";
import Popup from "components/common/popup/Popup";
import Row from "components/common/row/Row";
import Table from "components/common/table/Table";
import { TABLE_ITEMS_PER_PAGE } from "config/conf";

import { getAllSectors1Api, updateInterventionPointApi } from "config/apiConf";
import { allSectors1Module, updateInterventionPointModule } from "config/modulesConf";
import { IAllSectors1Data, IAllSectors1Result } from "containers/sectors1/ISectors";
import { IAllInterventionPointsResult } from "../IInterventionPoint";

import Checkbox from "components/common/checkbox/Checkbox";
import { ITableProps } from "components/common/table/ITable";
import NeuroButton from "components/buttons/neuroButton/NeuroButton";

interface IProps extends IPopupProps {
  userSettings?: ISmartState;
  interventionPoint: IAllInterventionPointsResult;
  allSectors1?: ISmartState;
  allSectors1Actions?: ISmartAction;
  updateInterventionPoint?: ISmartState;
  updateInterventionPointActions?: ISmartAction;
}

const UpdateInterventionPointSector1Popup = (props: IProps) => {
  const { addParams, getRouteFromParams } = useRoute();
  const [tblLoader, setTblLoader] = useState(false);
  const [tblData, setTblData] = useState() as any;
  const [crrPage, setCrrPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_ITEMS_PER_PAGE);
  const [tableSorter, setTableSorter] = useState({}) as any;
  const [tableFilters, setTableFilters] = useState({}) as any;
  const allSectors1: IAllSectors1Data = props.allSectors1?.data;
  const interventionPoint = props.interventionPoint;
  const uc = useChecks({single: true});

  useEffect(() => {
    props.allSectors1Actions?.reset();
    
    getData(crrPage, pageSize, tableFilters, tableSorter);

    return () => {
      props.allSectors1Actions?.reset();
    };

    // eslint-disable-next-line
  }, []);

  const getData = (cPage: number, pageSize: number, tblFilters: any, tblSorter: any) => {
    setCrrPage(cPage);
    setPageSize(pageSize);
    setTblLoader(true);

    const filter: any = { 
      pageNumber: cPage, 
    };

    calculatePageFilters(filter, tblFilters, tableFilters, setTableFilters);
    calculatePageFilters(filter, tblSorter, tableSorter, setTableSorter);
    
    props.allSectors1Actions?.httpCall(addParams(getAllSectors1Api, filter));
  };

  useEffect(() => {
    if (props.allSectors1?.opCompleted) {
      //Add Ids for uc checks
      allSectors1.results.forEach((sector1, index) => {
        sector1.id = index + 1;
      });

      setTblData(allSectors1.results);

      //Checks
      let newChecksData = deepClone(uc.checksData);
      if (props.interventionPoint.sector1)
      {
        var selected = allSectors1.results.find(element => element.sector1 === props.interventionPoint.sector1)
        if (selected)
          newChecksData[selected.id] = true;
      }
      uc.setData(newChecksData);
      setTblLoader(false);
    }
    // eslint-disable-next-line
  }, [props.allSectors1?.opCompleted]);

  useEffect(() => {
    if (props.allSectors1?.errors) {
      setTblLoader(false);
    }
    // eslint-disable-next-line
  }, [props.allSectors1?.errors]);

  useEffect(() => {
    setTblLoader(false);
    // eslint-disable-next-line
  }, [props.updateInterventionPoint?.opCompleted, props.updateInterventionPoint?.errors]);

  const save = (values: any) => {
    setTblLoader(true);
    var checkedIds = getArrayKeysFromValue(uc.checksData, true, ["all"]);
    var selectedSector1 = allSectors1.results.find(us => checkedIds.some((id : number) => id.toString() === us.id.toString()));
    if (selectedSector1)
    {
      props.updateInterventionPointActions?.httpCall(
        getRouteFromParams(updateInterventionPointApi, {id: interventionPoint.id}),
        {interventionPointId: interventionPoint.id, sector1: selectedSector1?.sector1 }
      );
    }
  }

  const disabled = false;
  return (
    <Popup scrollableContent {...props} bigSize={true} title={"Secteurs de l'utilisateur"} 
    footerContent={<Row><NeuroButton color="green" size={'normal'} onClick={save} param={uc.checksData} label={"Sauvegarder"} disabled={false} /></Row>}>
        <Table style={{marginBottom: `50px`}}
        totalEntries={allSectors1?.totalEntries}
        hidePagination={true}
        onChange={(cp: number, ps: number) => getData(cp, ps, tableFilters, tableSorter)}
        onHeaderFilterChange={(colId: any, value: any) => getData(1, pageSize, { [colId]: value }, tableSorter)}
        onSort={(st: any) => getData(crrPage, pageSize, tableFilters, st)}
        sortBy={tableSorter}
        maxPages={allSectors1?.pageCount}
        loader={tblLoader}
        data={tblData}
        crrPage={crrPage}
        selectedRows={uc.checksData}
        columns={[
          {
            headerFilterProps: {
              type: "number",
            },
            Header: "x",
            hideHeaderLabel: true,
            headerFilter: false,
            columnWidth: 30,
            Cell: (props: ITableProps<IAllSectors1Result>) => (
              <Checkbox
                disabled={disabled}
                onChange={(val: boolean) => uc.calculate(val, props.row.original.id)}
                checked={uc.checksData[props.row.original.id]}
              />
            ),
          },
          {
            Header: "Secteur",
            accessor: nameOf((n: IAllSectors1Result) => n.sector1),
            Cell: (props: ITableProps<IAllSectors1Result>) =>
              (props.row.original as IAllSectors1Result).sector1
                ? (props.row.original as IAllSectors1Result).sector1
                : null,
          },
        ]} />
    </Popup>
  );
};

export default smartConnect(
  [allSectors1Module, updateInterventionPointModule],
  [allSectors1Module, updateInterventionPointModule]
)(UpdateInterventionPointSector1Popup);
