import React, { useEffect, useState } from "react";
import { IRoute } from "interfaces/IRoute";
import { ISmartState, ISmartAction } from "interfaces/ISmart";
import { smartConnect } from "app/appUtils";
import { useRoute } from "hooks/useRoute";
import { nameOf } from "utils/nameOfUtils";

import Table from "components/common/table/Table";
import { TABLE_ITEMS_PER_PAGE } from "config/conf";
import { ITableProps } from "components/common/table/ITable";

import { calculatePageFilters } from "utils/pageUtils";

import { allAnimationsModule, allAnimationsTmpModule, cancellationRequestModule} from "config/modulesConf";
import { getAllAnimationsApi} from "config/apiConf";
import { IAllAnimationsData, IAllAnimationsResult  } from "containers/animations/IAnimations";

import Form from "components/common/form/Form";
import moment from 'moment';

import Switch from "components/common/switch/Switch"
import Row from "components/common/row/Row";
import AnimationStatus from "components/common/AnimationStatus/AnimationStatus";
import CircleButton from "components/buttons/circleButton/CircleButton";
import CancellationRequestPopup from "../popups/CancellationRequestPopup"
import DateSelector from "components/common/dateSelector/DateSelector";

import { FaTimes } from "react-icons/fa";

interface IProps {
  route: IRoute;
  dateFrom: Date;
  dateTo: Date;
  includeBackup: boolean;
  cancellationRequest?: ISmartState;
  cancellationRequestActions?: ISmartAction;
  allAnimations?: ISmartState;
  allAnimationsActions?: ISmartAction;
  allAnimationsTmp?: ISmartState;
  allAnimationsTmpActions?: ISmartAction;
}

const AllAnimationsList = (props: IProps) => {
  const { addParams } = useRoute();
  const [popup, setPopup] = useState<number>();
  const [tblLoader, setTblLoader] = useState(false);
  const [tblData, setTblData] = useState() as any;
  const [crrPage, setCrrPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_ITEMS_PER_PAGE);
  const [tableSorter, setTableSorter] = useState({}) as any;
  const [tableFilters, setTableFilters] = useState({}) as any;
  const data: IAllAnimationsData = props.allAnimations?.data;

  useEffect(() => {
    props.allAnimationsActions?.reset();
    props.allAnimationsTmpActions?.reset();
    
    getData(crrPage, pageSize, tableFilters, tableSorter);

    return () => {
      props.allAnimationsActions?.reset();
      props.allAnimationsTmpActions?.reset();
    };

    // eslint-disable-next-line
  }, [props.dateFrom, props.dateTo, props.includeBackup]);

  useEffect(() => {
    if (props.cancellationRequest?.opCompleted) {
      setPopup(undefined);
      props.cancellationRequestActions?.reset();
      getData(1, pageSize, tableFilters, tableSorter);
    }
    // eslint-disable-next-line
  }, [props.cancellationRequest?.opCompleted]);

  const getData = (cPage: number, pageSize: number, tblFilters: any, tblSorter: any) => {
    setCrrPage(cPage);
    setPageSize(pageSize);
    setTblLoader(true);

    const filter: any = { 
      pageNumber: cPage, 
      pageSize: pageSize,
      dateFrom: moment(props.dateFrom).format('YYYY-MM-DD'), 
      dateTo: moment(props.dateTo).format('YYYY-MM-DD'),
      includeBackup: props.includeBackup
    };

    calculatePageFilters(filter, tblFilters, tableFilters, setTableFilters);
    calculatePageFilters(filter, tblSorter, tableSorter, setTableSorter);

    props.allAnimationsActions?.httpCall(addParams(getAllAnimationsApi, filter));
  };

  useEffect(() => {
    if (props.allAnimations?.opCompleted) {
      setTblData(data.results);
      setTblLoader(false);
      //uc.newData(data.results);
    }
    // eslint-disable-next-line
  }, [props.allAnimations?.opCompleted]);

  useEffect(() => {
    if (props.allAnimationsTmp?.opCompleted) {
      props.allAnimationsTmpActions?.reset();
      getData(1, pageSize, tableFilters, tableSorter);
    }
    // eslint-disable-next-line
  }, [props.allAnimationsTmp?.opCompleted]);

  useEffect(() => {
    if (props.allAnimationsTmp?.errors || props.allAnimations?.errors) {
      setTblLoader(false);
    }
    // eslint-disable-next-line
  }, [props.allAnimationsTmp?.errors, props.allAnimations?.errors]);

  return (
    <Form
      fullHeight={true}
      tabsMode={true}
      noWrap={true}
    >
      <Table
        totalEntries={data?.totalEntries}
        onChange={(cp: number, ps: number) => getData(cp, ps, tableFilters, tableSorter)}
        onHeaderFilterChange={(colId: any, value: any) => getData(1, pageSize, { [colId]: value }, tableSorter)}
        onSort={(st: any) => getData(crrPage, pageSize, tableFilters, st)}
        sortBy={tableSorter}
        maxPages={data?.pageCount}
        loader={tblLoader}
        data={tblData}
        crrPage={crrPage}
        columns={[
          {
            Header: "Id",
            columnWidth: 1,
            accessor: nameOf((n: IAllAnimationsResult) => n.id),
            Cell: (props: ITableProps<IAllAnimationsResult>) =>
              (props.row.original as IAllAnimationsResult).id
                ? (props.row.original as IAllAnimationsResult).id
                : null,
          },
          {
            Header: "Campagne",
            columnWidth: 1,
            accessor: nameOf((n: IAllAnimationsResult) => n.campaignName),
            Cell: (props: ITableProps<IAllAnimationsResult>) =>
              (props.row.original as IAllAnimationsResult).campaignName
                ? (props.row.original as IAllAnimationsResult).campaignName
                : null,
          },
          {
            Header: "Date",
            columnWidth: 1,
            accessor: nameOf((n: IAllAnimationsResult) => n.startDate),
            Filter: DateSelector,
            filterProps: {
              dateFormat: "dd/MM/yyyy",
            },
            Cell: (props: ITableProps<IAllAnimationsResult>) => moment(props.value).format("DD/MM/yyyy"),
          },
          {
            Header: "POS",
            columnWidth: 1,
            accessor: nameOf((n: IAllAnimationsResult) => n.interventionPointCodePOS),
            Cell: (props: ITableProps<IAllAnimationsResult>) =>
              (props.row.original as IAllAnimationsResult).interventionPointCodePOS
                ? (props.row.original as IAllAnimationsResult).interventionPointCodePOS
                : null,
          },
          {
            Header: "Zone",
            columnWidth: 1,
            accessor: nameOf((n: IAllAnimationsResult) => n.sector3),
            Cell: (props: ITableProps<IAllAnimationsResult>) =>
              (props.row.original as IAllAnimationsResult).sector3
                ? (props.row.original as IAllAnimationsResult).sector3
                : null,
          },
          {
            Header: "Area",
            columnWidth: 1,
            accessor: nameOf((n: IAllAnimationsResult) => n.sector2),
            Cell: (props: ITableProps<IAllAnimationsResult>) =>
              (props.row.original as IAllAnimationsResult).sector2
                ? (props.row.original as IAllAnimationsResult).sector2
                : null,
          },
          {
            Header: "Secteur",
            columnWidth: 1,
            accessor: nameOf((n: IAllAnimationsResult) => n.sector1),
            Cell: (props: ITableProps<IAllAnimationsResult>) =>
              (props.row.original as IAllAnimationsResult).sector1
                ? (props.row.original as IAllAnimationsResult).sector1
                : null,
          },
          {
            Header: "Nom",
            accessor: nameOf((n: IAllAnimationsResult) => n.interventionPointName),
            Cell: (props: ITableProps<IAllAnimationsResult>) =>
              (props.row.original as IAllAnimationsResult).interventionPointName
                ? (props.row.original as IAllAnimationsResult).interventionPointName
                : null,
          },
          {
            Header: "Adresse",
            accessor: nameOf((n: IAllAnimationsResult) => n.interventionPointAddress),
            Cell: (props: ITableProps<IAllAnimationsResult>) =>
              (props.row.original as IAllAnimationsResult).interventionPointAddress
                ? (props.row.original as IAllAnimationsResult).interventionPointAddress
                : null,
          },
          {
            Header: "Animateur",
            accessor: nameOf((n: IAllAnimationsResult) => n.promoterName),
            Cell: (props: ITableProps<IAllAnimationsResult>) =>
              (props.row.original as IAllAnimationsResult).promoterName
                ? (props.row.original as IAllAnimationsResult).promoterName
                : null,
          },
          {
            Header: "Statut",
            accessor: nameOf((n: IAllAnimationsResult) => n.isActiv),
            columnWidth: 1,
            Filter: Switch,
            filterProps: { checked: tableFilters.isActiv, nullable: true, },
            Cell: (props: ITableProps<IAllAnimationsResult>) =>
              <AnimationStatus isActiv={props.row.original.isActiv} 
              cancellationRequested={props.row.original.cancellationRequested} />
          },
          {
            headerFilter: false,
            Header: "Actions",
            columnWidth: 30,
            center: true,
            sortable: false,
            Cell: (props: ITableProps<IAllAnimationsResult>) => (
              <Row center={true}>
                <CircleButton color="orange" disabled={(props.row.original.isActiv === false || props.row.original.cancellationRequested 
                || moment(props.row.original.startDate).isSameOrBefore(moment(), "day"))} param={props.row.original.id} onClick={setPopup} 
                content={<FaTimes size={20}/>}
                tooltip="Demander l'annulation"/>
              </Row>
            ),
          },
        ]}
      />
      {popup && <CancellationRequestPopup onClose={setPopup} missionId={popup} />}
    </Form>
  );
};
export default smartConnect(
  [allAnimationsModule, allAnimationsTmpModule, cancellationRequestModule],
  [allAnimationsModule, allAnimationsTmpModule, cancellationRequestModule]
)(AllAnimationsList);
