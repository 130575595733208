import React from "react";
import "./circleButton.scss";
import Button from "components/common/button/Button";
import { IButtonProps } from "components/common/button/IButtonProps";
//import { FaPlusCircle } from "react-icons/fa";

interface IProps extends IButtonProps {
  color?: string;
  size?: string;
}

const CircleButton = (props: IProps) => {
  return (
    <Button
      styleClass={"circleButton" + (props.color !== undefined ? ` circlebtn--${props.color}` : "") +  (props.size !== undefined ? ` size--${props.size}` : "")}
      tooltip={props.tooltip ? props.tooltip : ""}
      {...props}
    />
  );
};

export default CircleButton;
