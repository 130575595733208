import React from "react";
import { makeClassName, makeStyleProperties } from "utils/componentUtils";
import { IDefaultProps } from "interfaces/IDefaultProps";
import "./column.scss";

interface IProps extends IDefaultProps {
  children?: any;
  scrollable?: any;
}

const Column = (props: IProps) => {
  return <div className={makeClassName("column" + (props.scrollable === true ? " scrollable" : ""), props)} style={makeStyleProperties(props)}>
    {props.children}
    </div>;
};

export default Column;
