import React from "react";
import { makeClassName } from "utils/componentUtils";
import { IDefaultProps } from "interfaces/IDefaultProps";
import "./spinner.scss";

interface IProps extends IDefaultProps {
  className?: string;
}

const Spinner = (props: IProps) => {
  return (
    <div className={makeClassName("spinner", props)}>
      <div className={"background"}></div>
      <div className="content"></div>
    </div>
  );
};

export default Spinner;
