import React, { useState, useEffect, useRef } from "react";
import { makeClassName, makeStyleProperties } from "utils/componentUtils";
import "./dropdown.scss";
import { IDropdownProps } from "./IDropdownProps";
import { AiFillCaretDown } from "react-icons/ai";
import { AiFillCaretUp } from "react-icons/ai";
import Input from "../input/Input";
import Button from "../button/Button";
import { searchArrayIndexOfIndex, createArrayFromKey, searchArrayIndex, spliceMultiple, sortArray, sortArrayByIndex } from "utils/arrayUtils";
import { deepClone } from "utils/cloneUtils";
import { useTranslate } from "services/translationService";
import Spinner from "../spinner/Spinner";

const Dropdown = (props: IDropdownProps) => {
  const { t } = useTranslate();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState() as any;
  const [ident] = useState(props.ident ? props.ident : "value");
  const [value, setValue] = useState(props.multi !== true ? props.value : []);
  const [optionsIdent] = useState(props.optionsIdent ? props.optionsIdent : "label");
  const [selected, setSelected] = useState() as any;
  const ref = useRef(null);
  const [values, setValues] = useState(props.values) as any;

  useEffect(() => {
    document.addEventListener("mousedown", downOutside);

    return () => {
      document.removeEventListener("mousedown", downOutside);
    };

    // eslint-disable-next-line
  }, []);

  const defaultValueExists = () => {
    return props.defaultInactiveValue !== undefined && props.defaultInactiveValue !== null;
  };

  useEffect(() => {
    if (
      (props.value === null || props.value === undefined) &&
      defaultValueExists() &&
      props.defaultInactiveValue.name !== undefined &&
      props.defaultInactiveValue.isActiv === false
    )
      setSelected(props.defaultInactiveValue);

    // eslint-disable-next-line
  }, [props.defaultInactiveValue]);

  const downOutside = (e: any) => {
    if (!(ref.current as any).contains(e.target)) {
      setOpen(false);
    }
  };

  const onClick = () => {
    if (props.options !== undefined) {
      let options = props.options;
      if (!props.noSort) sortArray(props.options, t);
      if (props.sortIdent) sortArrayByIndex(props.options, props.sortIdent);
      setOptions(options);
      if (props.multi === true) filterOptions(values);
    }

    setOpen(true);
    if (props.onOpen) props.onOpen();
  };

  const inputChanged = (value: any) => {
    setValue(value);
    if (props.options) setOptions(searchArrayIndexOfIndex(props.options, optionsIdent, value, true));
    if (!open) setOpen(true);
    if (props.onInputChanged) props.onInputChanged(value);
  };

  const select = (option: any = null, send: boolean = true, _newValues: any = null) => {
    let newValues = [];
    if (!_newValues) {
      setSelected(option);
      if (props.multi === true) {
        if (option) {
          newValues = values ? deepClone(values) : [];
          newValues.push(option);
        }
      }
      if (option || send) {
        if (!props.multi === true) {
          setValue(option ? option[optionsIdent] : null);
          setOpen(false);
        } else {
          if (option) {
            setValues(newValues);
          } else {
            setValues(null);
          }
        }
        if (send)
          if (props.onNativeChange) {
            if (props.multi !== true)
              props.onNativeChange({
                target: {
                  name: props.name,
                  value: option ? option[ident] : "",
                },
              });
          }
      } else {
        setValue(null);
        setValues(null);
      }
    }
    if (send && props.onChange) {
      if (props.multi === true) props.onChange(_newValues ? _newValues : newValues);
      else {
        if (props.selectOnlyIdent === true) {
          props.onChange(option ? option[props.ident as any] : "");
        } else {
          props.onChange(option);
        }
      }
    }

    if (send && props.onNativeChange) {
      if (props.multi === true)
        props.onNativeChange({
          target: {
            name: props.name,
            value: createArrayFromKey(_newValues ? _newValues : newValues, props.ident ? props.ident : "value"),
          },
        });
    }

    if (props.multi === true) {
      filterOptions(_newValues ? _newValues : newValues);
    }
  };

  const onEnter = () => {
    if (value !== undefined && value !== "") {
      if (props.options) {
        const flt = searchArrayIndexOfIndex(props.options, optionsIdent, value);
        if (flt.length > 0) select(flt[0]);
      }
    }
    if (value === "") select();
    setOpen(false);
  };

  const deleteSelection = (send: boolean = true) => {
    select(null, send);
    if (props.multi !== true) setOpen(false);
    if (props.onClear) props.onClear();
  };

  useEffect(() => {
    if (props.value !== null && props.value !== undefined) {
      if (props.multi === true) {
        const newValues = deepClone(props.value);
        setValues(newValues);
      } else {
        select(props.value, false);
      }
    }
    if (props.clearOnNullValue === true && props.value === null) {
      select(null, false);
    }
    // eslint-disable-next-line
  }, [props.value]);

  useEffect(() => {
    if (props.options === undefined) return;
    if (
      (props.options !== undefined && options && options.length && options.length > 0 && props.options.length === 0) ||
      props.options.length === undefined
    ) {
      deleteSelection(false);
    }
    if (props.autoSelectIfOneItem === true && props.options.length && props.options.length === 1) {
      select(props.options[0], true);
    }
    // eslint-disable-next-line
  }, [props.options]);

  useEffect(() => {
    setValues(props.values);
    // eslint-disable-next-line
  }, [props.values]);

  const buildOptions = (option: any, index: number) => {
    const od = optionsIdent.split(",");
    let ods = "";
    od.forEach((element: any) => {
      ods += ods === "" ? option[element] : " " + ods;
    });
    return <Button param={option} onClick={select} key={index} label={ods}></Button>;
  };

  const deleteValue = (index: number) => {
    const newValues = deepClone(values);
    newValues.splice(index, 1);
    setValues(newValues);
    select(null, true, newValues);
    if (props.onDelete) props.onDelete(props.values[index]);
  };

  const selectAll = () => {
    const newValues: any = [];
    props.options.forEach((element: any) => {
      const el: any = deepClone(element);
      newValues.push(el);
    });
    setValues(newValues);
    select(null, true, newValues);
    setOpen(false);
  };

  const filterOptions = (newValues: any) => {
    if (props.options) {
      let newOptions: any = deepClone(props.options);
      if (newValues && newValues.length > 0) {
        const key = props.ident ? props.ident : "value";
        const inds: any = [];
        newValues.forEach((element: any) => {
          const ind = searchArrayIndex(props.options, key, element[key]);
          if (ind >= 0) inds.push(ind);
        });

        newOptions = spliceMultiple(props.options, inds);
      }
      setOptions(newOptions);
    }
  };

  const buildValues = (vl: any, index: number) => {
    const v = vl[props.optionsIdent ? props.optionsIdent : "value"];
    return (
      <Button param={index} onClick={deleteValue} key={index}>
        {v}
      </Button>
    );
  };

  const contentStyle: any = {};
  if (props.maxHeight !== undefined) {
    contentStyle.maxHeight = props.maxHeight;
  }

  return (
    <div ref={ref} className={makeClassName("dropdown" + (props.multi === true && props.filter === true ? " multiEdit" : ""), props)}
    style={makeStyleProperties(props, props.style)}>
      {props.label && (
        <span className="label">
          {props.label}
          {props.required ? <span className="required"></span> : ""}
        </span>
      )}
      <div className={"header" + (props.multi === true ? " multi" : "")} onClick={onClick}>
        <Input
          defaultInactiveValue={defaultValueExists() && selected !== undefined && selected !== null ? selected.name : null}
          autoComplete={"off"}
          name={props.name}
          placeholder={props.placeholder}
          onChange={inputChanged}
          inputProps={{
            readOnly: !props.filter,
          }}
          styleClass={values && values.length > 0 ? "firefoxDownsizeInput" : ""}
          onEnter={onEnter}
          value={value}
          contentBefore={props.multi === true && values && <div className="values">{values.map(buildValues)}</div>}
          {...props.inputProps}
        >
          {((selected && props.deletable !== false) || (values && values.length > 0)) && <Button onClick={deleteSelection} styleClass="delete" />}
          <Button param={!open} styleClass="caret">
            {open ? <AiFillCaretUp /> : <AiFillCaretDown />}
          </Button>
        </Input>
      </div>
      {open && (
        <div style={contentStyle} className={"content" + (props.label ? "" : " noLabel")}>
          {props.selectAll !== undefined && <Button onClick={selectAll} label={props.selectAll} />}
          {props.options !== undefined && options && options.map && options.map(buildOptions)}
          {props.customContent && <div className="customContent">{props.customContent}</div>}
        </div>
      )}
      {props.error ? <span className="error">{props.error}</span> : null}
      {props.loader === true && <Spinner relative={true} micro={true} />}
    </div>
  );
};

export default Dropdown;
