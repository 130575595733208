import React, { useState, useEffect } from "react";
import { makeClassName } from "utils/componentUtils";
import { IDefaultProps } from "interfaces/IDefaultProps";
import "./checkbox.scss";
import { GoCheck } from "react-icons/go";
import Button from "../button/Button";
import { AiOutlineCaretDown } from "react-icons/ai";
import { AiOutlineCaretUp } from "react-icons/ai";

interface IProps extends IDefaultProps {
  onChange?: any;
  onNativeChange?: any;
  name?: any;
  children?: any;
  content?: any;
  checked?: boolean;
  label?: string;
  labelContent?: boolean;
  error?: string;
  type?: number;
  txExpand?:string;
  txCollapse?:string;
}

const Checkbox = (props: IProps) => {
  const [checked, setChecked] = useState(props.checked);

  const change = (val: boolean) => {
    setChecked(val);
    if (props.onNativeChange) props.onNativeChange({ target: { name: props.name, value: val } });
    if (props.onChange) props.onChange(val);
  };

  useEffect(() => {
    if (props.checked !== undefined) setChecked(props.checked);
  }, [props.checked]);

  let cnt = null;
  let label = props.label;
  if (props.type === 2) {
    cnt = <AiOutlineCaretDown />;
    label = props.txExpand;
  }
  if (checked) {
    cnt = <GoCheck />;
    if (props.type === 2) {
      cnt = <AiOutlineCaretUp />;
      label = props.txCollapse;
    }
  }

  let btnCls = "btn";
  if (props.type !== undefined) btnCls += " type" + props.type;
  return (
    <div className={makeClassName("checkbox" + (checked ? " checked" : ""), props)}>
      {props.labelContent === true && <span className={"label labelContent"}></span>}
      <div className="content">
        <Button styleClass={btnCls} param={!checked} onClick={change} content={cnt}></Button>
        <div className="cnt">
          {label && <span className={"label"}>{label}</span>}
          {props.content}
          {props.children}
        </div>
      </div>
      {props.error !== undefined && props.error !== "" ? <div className={"error"}>{props.error}</div> : null}
    </div>
  );
};

export default Checkbox;
