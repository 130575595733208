import React, { useState, useEffect } from "react";
import PageContent from "pageWrapper/pageContent/PageContent";
import { ISmartState, ISmartAction } from "interfaces/ISmart";
import { smartConnect } from "app/appUtils";
import Chart from "react-apexcharts";
import "./charts.scss";

import { sectors3DropdownModule, sector3StatisticsModule, userSettingsModule } from "config/modulesConf";
import { getSector3StatisticsApi, getSectors3DropdownApi} from "config/apiConf";

import Row from "components/common/row/Row";
import { IRoute } from "interfaces/IRoute";
//import MonthPagination from "components/common/table/monthPagination/MonthPagination";
import moment from "moment";
import { deepClone } from "utils/cloneUtils";
import { calculatePageFilters } from "utils/pageUtils";
import { ISector3StatisticsData } from "./IStatistics";
import { FaCircleNotch } from "react-icons/fa";
import { ISectors3Dropdown } from "containers/sectors1/ISectors";
import Dropdown from "components/common/dropdown/Dropdown";
import { arrayFilterByArray } from "utils/arrayUtils";
import { useTranslate } from "services/translationService";
import * as lngConf from "config/lngConf";
import Column from "components/common/column/Column";
import DateSelector from "components/common/dateSelector/DateSelector";
import Label from "components/common/label/Label";
import { IAllAnimationsFilters } from "containers/animations/IAnimations";
import { nameOf } from "utils/nameOfUtils";
import Switch from "components/common/switch/Switch";
import { IUserSettingsData } from "interfaces/modules/IUserSettings";
import { BusinessLeader, Administrator, SuperAdministrator } from "config/permsConf";
import BookingStatistics from "components/chart/BookingStatistics";


interface IProps {
  route: IRoute;
  sectors3Dropdown?: ISmartState;
  sectors3DropdownActions?: ISmartAction;
  sector3Statistics?: ISmartState;
  sector3StatisticsActions?: ISmartAction;
  userSettings?: ISmartState;
}

interface ISize {
  width: number
  height: number
}

const Statistics = (props: IProps) => {
  const { t } = useTranslate();
  //const [currentMonth, setCurrentMonth] = useState(new Date());
  const [dateFrom, setDateFrom] = useState(moment().clone().startOf("month")) as any;
  const [dateTo, setDateTo] = useState(moment().clone().endOf("month").toDate()) as any;
  const [includeBackup, setIncludeBackup] = useState(false) as any;
  const [tblLoader, setTblLoader] = useState(true);
  const [crrPage, setCrrPage] = useState(1);
  const [pageSize, setPageSize] = useState(10000);
  const [tableSorter, setTableSorter] = useState({}) as any;
  const [tableFilters, setTableFilters] = useState({}) as any;
  const userSettingsData: IUserSettingsData = props.userSettings?.data;
  const sectors3DropdownData: ISectors3Dropdown = props.sectors3Dropdown?.data;
  const sector3StatisticsData: ISector3StatisticsData = props.sector3Statistics?.data;
  const [sectors3DdlLoader, setLoaderDdlSectors3D] = useState(true);
  const [filters, setFilters] = useState({}) as any;
  const sector1 : string = userSettingsData.role !== BusinessLeader ? "Multi" : userSettingsData.sectors1.length < 1 ? "Non Défini" : userSettingsData.sectors1[0];
  const quota : number = userSettingsData.quota;
  const campaignName : string = userSettingsData.campaignName;
  const [windowSize, setWindowSize] = useState<ISize>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  useEffect(() => {
    if (dateFrom && dateTo)
    {
      window.scrollTo(0, 0)
      setTblLoader(true);
      getData(crrPage, pageSize, filters, tableSorter);
    }

    // eslint-disable-next-line
  }, [includeBackup, dateFrom, dateTo, filters]);

  useEffect(() => {
    if (props.sectors3Dropdown?.opCompleted || props.sectors3Dropdown?.errors) {
      setLoaderDdlSectors3D(false);
    }
    // eslint-disable-next-line
  }, [props.sectors3Dropdown?.opCompleted, props.sectors3Dropdown?.errors]);

  useEffect(() => {
    props.sector3StatisticsActions?.reset();
    props.sectors3DropdownActions?.reset();
    props.sectors3DropdownActions?.httpCall(getSectors3DropdownApi);

    return () => {
      props.sector3StatisticsActions?.reset();
      props.sectors3DropdownActions?.reset();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.sector3Statistics?.opCompleted) {
      setTblLoader(false);
    }
    // eslint-disable-next-line
  }, [props.sector3Statistics?.opCompleted]);

  const buildHeader = () => {
    return (
      <tr key={'tr1'}>
        <th key={'th1'} >
          <div style={{ justifyContent: "center" }} className={"headerLabel"}>
            {'Zones'}
          </div>
        </th>
        <th key={'th2'} >
          <div style={{ justifyContent: "center", width: windowSize.width - 300 }} className={"headerLabel"}>
            {'Secteurs'}
          </div>
        </th>
        <th key={'th3'} >
          <Column style={{ justifyContent: "center" }} className={"headerLabel"}>
            <span>{'Total'}</span>
            <span style={{ color: "red" }}>{sector3StatisticsData?.results?.reduce((a, b) => a + b.totalMissions, 0)}</span>
          </Column>
        </th>
        <th key={'th4'} >
          <Column style={{ justifyContent: "center" }} className={"headerLabel"}>
            <span>{'BackOffice'}</span>
            <span style={{ color: "red" }}>{sector3StatisticsData?.results?.reduce((a, b) => a + b.totalMissionsBackOffice, 0)}</span>
          </Column>
        </th>
      </tr>
    )
  };

  const buildRows = () => {
    if (sector3StatisticsData != null && Array.isArray(sector3StatisticsData.results))
      return (sector3StatisticsData.results.map((zone, index) => {
        var newOptions = Object.assign({}, options);
        newOptions.xaxis = {categories: [zone.sector3]}
        var series = zone.sector1Statistics.map(s1 => ({ name: s1.sector1, data: [s1.totalMissions] }));
        return (<tr key={index}>
          <td key={`A${index}`}>
            <span>{zone.sector3}</span>
          </td>
          <td key={`B${index}`} height="50">
            {(series.some(item => item.data[0] !== 0)) 
            ? <Chart options={newOptions} series={series} type={"bar"} height="50" width={windowSize.width - 300}/> 
            : null}
          </td>
          <td key={`C${index}`} style={{textAlign: 'center'}}>
            <span>{zone.totalMissions}</span>
          </td>
          <td key={`D${index}`} style={{textAlign: 'center'}}>
            <span>{zone.totalMissionsBackOffice}</span>
          </td>
        </tr>)
      }));
    else
      return null;
  };


  const getData = (cPage: number, pageSize: number, tblFilters: any, tblSorter: any) => {
    setCrrPage(cPage);
    setPageSize(pageSize);
    setTblLoader(true);

    const filter: any = { 
      pageNumber: cPage, 
      pageSize: pageSize,
    };

    calculatePageFilters(filter, tblFilters, tableFilters, setTableFilters);
    calculatePageFilters(filter, tblSorter, tableSorter, setTableSorter);

    let newFilter = deepClone(filter);
    newFilter.sortColumn = 'sector3';
    newFilter.sortOrder = 'asc';

    // monthSelector?
    // newFilter.startDate = moment(currentMonth).clone().startOf("month").format("YYYY-MM-DD");
    // newFilter.endDate = moment(currentMonth).clone().endOf("month").format("YYYY-MM-DD");
    newFilter.includeBackUp = includeBackup;
    newFilter.startDate = moment(dateFrom).format("YYYY-MM-DD");
    newFilter.endDate = moment(dateTo).format("YYYY-MM-DD");

    props.sector3StatisticsActions?.httpCall(getSector3StatisticsApi, newFilter);
  };

  let options: any = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      sparkline: {
        enabled: true
      },
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '100%',
        columnWidth: '100%',
      },
    },
    colors: ['#2E93fA', '#FF9800', '#16A085', '#CB4335', '#2980B9', '#40E0D0', '#95A5A6', '#27AE60', '#F1C40F', '#E74C3C',
                '#34495E', '#616A6B', '#8E44AD', '#CCCCFF', '#DE3163', '#9FE2BF'
              ],
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      formatter: (val : any, opt: any) => {
          return opt.w.config.series[opt.seriesIndex].data[0]
      },
      offsetX: 0,
    },
    tooltip: {
      y: {
        formatter: (val: any) => {
          return val + " Animations"
        }
      }
    },
    fill: {
      opacity: 1
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
  };

  const spokenLanguagesDropdownChange = (filter: any, dataIsFetching?: boolean) => {
    let sectors3;
    if (dataIsFetching) sectors3 = filter;
    else
    sectors3 = filter.map((m: any) => {
        return m.id;
      });
    registerFilter(
      'sectors3',
      sectors3.length > 0 ? sectors3 : ""
    );
  };

  const registerFilter = (name: any, value: any) => {
    const newFilters = deepClone(filters);
    newFilters[name] = value;
    setFilters(newFilters);
  };

  var admin = userSettingsData.role === Administrator || userSettingsData.role === SuperAdministrator;

  return (
    <PageContent
      actions={ userSettingsData.role === BusinessLeader &&[
      <Row spaceBetween={true} middle={true} fullSize={true} marginTop={'-40px'} marginBottom={'-40px'}>
          <Label name={`Secteur : ${sector1} `} bold={true} labelSize="big"/>
          <Column alignItems='center'>
            <Label name={`${campaignName}`} bold={true} labelSize="normal" spaceBetween={true}/>
            <Label name={`Objectif :  ${quota}`} bold={true} labelSize="normal" spaceBetween={true}/>
          </Column>
          <BookingStatistics />
      </Row>]}
  >
        <Row spaceBetween alignItems='flex-end'>
          <Row minWidth='200px'>
            <Dropdown display={userSettingsData.role !== BusinessLeader ? 'flex' : 'none'}
              loader={sectors3DdlLoader}
              filter={true}
              autoSelectIfOneItem={true}
              clearOnNullValue={true}
              placeholder={t(lngConf.PH_SELECT)}
              value={arrayFilterByArray(sectors3DropdownData, "id", filters['sectors3'])}
              onChange={(filter: any) => spokenLanguagesDropdownChange(filter)}
              label={"Zones"}
              options={sectors3DropdownData}
              optionsIdent={"name"}
              ident={"id"}
              multi={true}
            />
          </Row>
          <Row justifyContent='center'>
            <Label name="Du" bold={true} labelSize="normal"/>
            <DateSelector
              name={nameOf((n: IAllAnimationsFilters) => n.dateFrom)}
              onChange={(filter: Date) => setDateFrom(filter)}
              dateFormat={"dd/MM/yyyy"}
              value={dateFrom}
            />
            <Label name="Au" bold={true} labelSize="normal"/>
            <DateSelector fullSize={true}
              name={nameOf((n: IAllAnimationsFilters) => n.dateTo)}
              onChange={(filter: Date) => setDateTo(filter)}
              dateFormat={"dd/MM/yyyy"}
              value={dateTo}
            />
          </Row>
          {/* <MonthPagination currentMonth={currentMonth} onCurrentMonthChange={setCurrentMonth} /> */}
          <Row minWidth='200px'>
            <Row justifyContent='center' display={admin ? 'flex' : 'none'}>
              <Label name="Campagnes Back-up" bold={true} labelSize="normal"/>
              <Switch
                  checked={includeBackup}
                  onChange={(value: boolean) => setIncludeBackup(value)}
                />
            </Row>
           </Row>
        </Row>

        <div className={"table"}>
          <div className="table-content">
            <table cellSpacing={0}>
              <thead>
                {buildHeader()}
              </thead>
              <tbody style={{ opacity: tblLoader === true ? 0.4 : 1 }}>
                {buildRows()}
              </tbody>
            </table>
          </div>
          {tblLoader === true && (
              <div style={{ top: `${20 + 20}px` }} className="spinContainer">
                <FaCircleNotch className="spinner" />
              </div>
            )}
        </div>
    </PageContent>
  );
};

export default smartConnect([userSettingsModule, sector3StatisticsModule, sectors3DropdownModule], [sector3StatisticsModule, sectors3DropdownModule])(Statistics);