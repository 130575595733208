import React, { useState, useEffect } from "react";
import { useRoute } from "hooks/useRoute";
import { ISmartState, ISmartAction } from "interfaces/ISmart";
import { smartConnect } from "app/appUtils";
import { nameOf } from "utils/nameOfUtils";
import { useChecks } from "hooks/useChecks";

import { ITableProps } from "components/common/table/ITable";
import { calculatePageFilters } from "utils/pageUtils";
import Table from "components/common/table/Table";

import { allInterventionPointsModule } from "config/modulesConf";
import { getAllInterventionPointsApi } from "config/apiConf";
import { IAllInterventionPointsData, IAllInterventionPointsResult } from "containers/interventionPoints/IInterventionPoint";

import Checkbox from "components/common/checkbox/Checkbox";
import { deepClone } from "utils/cloneUtils";

interface IProps {
  onSelectInterventionPoint: any;
  allInterventionPoints?: ISmartState;
  allInterventionPointsActions?: ISmartAction;
  forcedId?: number;
}

const SelectInterventionPoint = (props: IProps) => {
  const { addParams } = useRoute();
  const uc = useChecks({ single: true });
  const [tblLoader, setTblLoader] = useState(false);
  const [tblData, setTblData] = useState() as any;
  const [crrPage, setCrrPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableSorter, setTableSorter] = useState({}) as any;
  const [tableFilters, setTableFilters] = useState({}) as any;
  const allInterventionPoints: IAllInterventionPointsData = props.allInterventionPoints?.data;
  const [selectedInterventionPoint, setSelectedInterventionPoint]: [IAllInterventionPointsResult, any] = useState(null) as any;

  useEffect(() => {
    return () => {
      props.allInterventionPointsActions?.reset();
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.forcedId && props.forcedId > 0) {
      onHeaderFilterChange("id", props.forcedId);
    }
    // eslint-disable-next-line
  }, [props.forcedId])

  const getData = (cPage: number, pageSize: number, tblFilters: any, tblSorter: any) => {
    setCrrPage(cPage);
    setPageSize(pageSize);

    const filter: any = { pageNumber: cPage, pageSize: pageSize };
    calculatePageFilters(filter, tblFilters, tableFilters, setTableFilters);
    calculatePageFilters(filter, tblSorter, tableSorter, setTableSorter);

    let newFilter = filter;
    if (pageSize === 0) {
      newFilter = deepClone(filter);
      newFilter.pageSize = "";
    }
    if (!tblFilters.id)
      delete newFilter.id

    props.allInterventionPointsActions?.httpCall(addParams(getAllInterventionPointsApi, newFilter));
    setTblLoader(true);
    //props.interventionPointsActions?.functionCall( () => { return { data: mockInterventionPoints } } );
  };

  useEffect(() => {
    if (props.allInterventionPoints?.opCompleted) {
      if (props.forcedId && props.forcedId > 0)
        allInterventionPoints.results = allInterventionPoints.results.filter(x => x.id === props.forcedId);
      
      setTblData(allInterventionPoints.results);
      if (allInterventionPoints.results.length === 1)
        selectTheOnlyOne(allInterventionPoints.results[0]);

      setTblLoader(false);
    }
    // eslint-disable-next-line
  }, [props.allInterventionPoints?.opCompleted]);

  useEffect(() => {
    if (props.allInterventionPoints?.errors) {
    }
    // eslint-disable-next-line
  }, [props.allInterventionPoints?.errors]);

  const selectTheOnlyOne = (interventionPoint: IAllInterventionPointsResult) => {
    uc.calculate(true, interventionPoint.id);
    setSelectedInterventionPoint(interventionPoint);
    props.onSelectInterventionPoint(interventionPoint);
  };

  const selectRow = (row: any) => {
    uc.calculate(true, row.original.id);
    setTblData(allInterventionPoints.results.filter(ip => ip.id === row.original.id));
    setSelectedInterventionPoint(row.original);
    props.onSelectInterventionPoint(row.original);
  };

  const onHeaderFilterChange = (colId: any, value: any) => {
    uc.calculate(false);
    setSelectedInterventionPoint(null);
    props.onSelectInterventionPoint(null);
    getData(1, pageSize, { [colId]: value }, tableSorter)
  };

  var disabled = false;
  return (
    <Table
      totalEntries={allInterventionPoints?.totalEntries}
      onChange={(cp: number, ps: number) => getData(cp, ps, tableFilters, tableSorter)}
      onHeaderFilterChange={onHeaderFilterChange}
      onSort={(st: any) => getData(crrPage, pageSize, tableFilters, st)}
      sortBy={tableSorter}
      maxPages={allInterventionPoints?.pageCount}
      loader={tblLoader}
      data={tblData}
      crrPage={crrPage}
      onRowSelect={selectRow}
      selectedRows={uc.checksData}
      columns={[
        {
          headerFilterProps: {
            type: "number",
          },
          Header: "x",
          hideHeaderLabel: true,
          headerFilter: false,
          columnWidth: 30,
          Cell: (props: ITableProps<IAllInterventionPointsResult>) => (
            <Checkbox
              disabled={disabled}
              onChange={(val: boolean) => selectRow(props.row)}
              checked={selectedInterventionPoint !== null && selectedInterventionPoint.id === props.row.original.id}//uc.checksData[props.row.original.id]}
            />
          ),
        },
        {
          Header: "Code POS",
          columnWidth: 150,
          accessor: nameOf((n: IAllInterventionPointsResult) => n.codePOS),
          Cell: (props: ITableProps<IAllInterventionPointsResult>) =>
            (props.row.original as IAllInterventionPointsResult).codePOS
              ? (props.row.original as IAllInterventionPointsResult).codePOS
              : null,
        },
        {
          Header: "Zone",
          columnWidth: 120,
          accessor: nameOf((n: IAllInterventionPointsResult) => n.sector3),
          Cell: (props: ITableProps<IAllInterventionPointsResult>) =>
            (props.row.original as IAllInterventionPointsResult).sector3
              ? (props.row.original as IAllInterventionPointsResult).sector3
              : null,
        },
        {
          Header: "Area",
          columnWidth: 1,
          accessor: nameOf((n: IAllInterventionPointsResult) => n.sector2),
          Cell: (props: ITableProps<IAllInterventionPointsResult>) =>
            (props.row.original as IAllInterventionPointsResult).sector2
              ? (props.row.original as IAllInterventionPointsResult).sector2
              : null,
        },
        {
          Header: "Secteur",
          columnWidth: 1,
          accessor: nameOf((n: IAllInterventionPointsResult) => n.sector1),
          Cell: (props: ITableProps<IAllInterventionPointsResult>) =>
            (props.row.original as IAllInterventionPointsResult).sector1
              ? (props.row.original as IAllInterventionPointsResult).sector1
              : null,
        },
        {
          Header: "Point de Vente",
          accessor: nameOf((n: IAllInterventionPointsResult) => n.name),
          Cell: (props: ITableProps<IAllInterventionPointsResult>) =>
            (props.row.original as IAllInterventionPointsResult).name
              ? (props.row.original as IAllInterventionPointsResult).name
              : null,
        },
        {
          Header: "Rue",
          accessor: nameOf((n: IAllInterventionPointsResult) => n.addressStreet),
          Cell: (props: ITableProps<IAllInterventionPointsResult>) =>
            (props.row.original as IAllInterventionPointsResult).addressStreet
              ? (props.row.original as IAllInterventionPointsResult).addressStreet
              : null,
        },
        {
          Header: "Code Postal",
          columnWidth: 150,
          accessor: nameOf((n: IAllInterventionPointsResult) => n.addressZip),
          Cell: (props: ITableProps<IAllInterventionPointsResult>) =>
            (props.row.original as IAllInterventionPointsResult).addressZip
              ? (props.row.original as IAllInterventionPointsResult).addressZip
              : null,
        },
        {
          Header: "Ville",
          accessor: nameOf((n: IAllInterventionPointsResult) => n.addressCity),
          Cell: (props: ITableProps<IAllInterventionPointsResult>) =>
            (props.row.original as IAllInterventionPointsResult).addressCity
              ? (props.row.original as IAllInterventionPointsResult).addressCity
              : null,
        }
      ]}
    />);
}

export default smartConnect([allInterventionPointsModule], [allInterventionPointsModule])(SelectInterventionPoint);
