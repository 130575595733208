import React, { useEffect, useState } from "react";
import { makeClassName } from "utils/componentUtils";
import "./animationStatus.scss";
import { IDefaultProps } from "interfaces/IDefaultProps";
import { FaCheckCircle, FaTimesCircle, FaMinusCircle } from "react-icons/fa";

interface IProps extends IDefaultProps {
  isActiv: boolean;
  cancellationRequested: boolean;
}

const AnimationStatus = (props: IProps) => {
  const [content, setContent] = useState<JSX.Element>();
  const [toolTip, setToolTip] = useState<string>("");

  useEffect(() => {
    if (props.isActiv && !props.cancellationRequested)
    {
      setContent(<FaCheckCircle color='#046448' size={30}/>) //Green
      setToolTip("Confirmé");
    }
    else if (props.isActiv && props.cancellationRequested)
    {
      setContent(<FaMinusCircle color='#ef8157' size={30}/>) //Orange
      setToolTip("En cours d'annulation");
    }
    else if (!props.isActiv)
    {
      setContent(<FaTimesCircle color='#bb0c0c' size={30}/>) //Red
      setToolTip("Annulé");
    }

    // eslint-disable-next-line
  }, [props.isActiv, props.cancellationRequested]);

  return (
    <div className={makeClassName("AnimationStatus", props)}>
      <span className="tooltiptext">{toolTip}</span>
      {content !== undefined && content}
    </div>
  );
};

export default AnimationStatus;
