import React, { useState, useEffect } from "react";
import { ITabItem } from "components/common/tabs/ITabs";
import Button from "components/common/button/Button";
import { makeClassName } from "utils/componentUtils";
import { IDefaultProps } from "interfaces/IDefaultProps";
import "./tabs.scss";
import Spinner from "../spinner/Spinner";
import { searchArrayIndex } from "utils/arrayUtils";
import { useRoute } from "hooks/useRoute";

interface IProps extends IDefaultProps {
  items: Array<ITabItem>;
  activeTab?: number;
  showPreloader?: boolean;
  firstOnly?: boolean;
  queries?: any;
  showTabs?: boolean;
}

const Tabs = (props: IProps) => {
  const [activeTab, setActiveTab] = useState(props.activeTab !== undefined ? props.activeTab : 0);
  const { getQuery, getQueries, setQueries } = useRoute();

  useEffect(() => {
    const tabFound = getQuery("tab");
    if (activeTab < 0) return;
    if (props.items.length > activeTab && tabFound !== props.items[activeTab].path) {
      if (!tabFound) {
        navigate(props.items[0].path);
      } else setActiveTab(searchArrayIndex(props.items, "path", tabFound));
    }
    // eslint-disable-next-line
  }, [props.items]);

  const navigate = (q: string) => {
    let qs: any = {};
    getQueries((value: any) => Object.assign(qs, value));
    qs["tab"] = q;
    setQueries(qs);
  };

  const changeTab = (index: any) => {
    if (activeTab !== index) {
      setActiveTab(index);
      navigate(props.items[index].path);
    }
  };

  const buildHeader = (tab: ITabItem, index: number) => {
    if (tab.hide === true) return null;
    if (props.firstOnly === true && index > 0) return null;
    return <Button styleClass={activeTab === index ? " active" : ""} key={index} label={tab.label} param={index} onClick={changeTab} />;
  };

  if (activeTab < 0) return null;
  const Content = props.items.length > 0 ? props.items[activeTab].content : null;
  return (
    <div className={makeClassName("tabs", props)}>
      <div className="header" style={{display: props.showTabs ? 'block' : 'none'}}>{props.items.map(buildHeader)}</div>
      <div className="content">
        {props.items[activeTab].content && <Content {...props.items[activeTab].props} />}
        {props.showPreloader === true && <Spinner />}
      </div>
    </div>
  );
};

export default Tabs;
