import React, { useEffect, useState } from "react";
import { ISmartState, ISmartAction } from "interfaces/ISmart";
import { smartConnect } from "app/appUtils";
import { useRoute } from "hooks/useRoute";
import Chart from "react-apexcharts";
import moment from 'moment'

import { bookingStatisticsModule } from "config/modulesConf";

import { IBookingStatisticsData } from "./ICharts";
import { getBookingStatisticsApi } from "config/apiConf";
import { getNumberOfDaysInMonth } from "utils/dateUtils";
import "./charts.scss";


interface IProps {
  bookingStatistics?: ISmartState;
  bookingStatisticsActions?: ISmartAction;
}

const BookingStatistics = (props: IProps) => {
  const { addParams } = useRoute();
  const [max, setMax] = useState<number>(10);
  const [series, setSeries] = useState<number[]>([]);
  const [firstMonthColor, setFirstMonthColor] = useState<string>("#0000FF")
  const data: IBookingStatisticsData = props.bookingStatistics?.data;

  useEffect(() => {
    props.bookingStatisticsActions?.reset();
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.bookingStatistics?.opCompleted) {
      setSeries([data.currentMonth.count, data.nextMonth.count]);
      setMax(Math.max(data.currentMonth.total, data.nextMonth.total));
      setFirstMonthColor(getColorForCurrentMonth());
    }
    // eslint-disable-next-line
  }, [props.bookingStatistics?.opCompleted]);

  useEffect(() => {
    if (props.bookingStatistics?.errors) {
      setSeries([]);
    }
    // eslint-disable-next-line
  }, [props.bookingStatistics?.errors]);

  const getData = () => {
    props.bookingStatisticsActions?.httpCall(addParams(getBookingStatisticsApi));
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  var categories = [
    `${capitalizeFirstLetter(moment().format("MMMM"))} ${data.currentMonth?.count} / ${data?.currentMonth?.total}`,
    `${capitalizeFirstLetter(moment().add(1, 'M').format("MMMM"))} ${data?.nextMonth?.count} / ${data?.nextMonth?.total}`
  ]

  const getColorForCurrentMonth = () => {
    if (data?.currentMonth?.total) {
      const date = new Date();
      const daysInCurrentMonth = getNumberOfDaysInMonth(date.getMonth(), date.getFullYear());
      const quotaRatio = data?.currentMonth?.count / data.currentMonth?.total;
      const monthRatio = date.getDate() / daysInCurrentMonth;

      if (quotaRatio + 0.1 < monthRatio) {
        return "#F2685C"; //red
      } else if (quotaRatio - 0.1 > monthRatio) {
        return "#23DC3D"; //green
      }
    }
    return "#0000FF"; //blue
  }

  var options = {
    colors: [firstMonthColor, "#0000FF"],
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      show: false,
      categories: categories,
      max: max,
      labels: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: true,
    }
  };

  return (
    <div className="chartBox">
      <Chart options={options} series={[{ data: series }]} type={'bar'} />
    </div>
  );
};
export default smartConnect([bookingStatisticsModule], [bookingStatisticsModule])(BookingStatistics);
