import React, { useEffect, useState } from "react";
import { useRoute } from "hooks/useRoute";
import PageContent from "pageWrapper/pageContent/PageContent";
import { IRoute } from "interfaces/IRoute";
import { nameOf } from "utils/nameOfUtils";
import { smartConnect } from "app/appUtils";
import { ISmartState, ISmartAction } from "interfaces/ISmart";

import Table from "components/common/table/Table";
import { TABLE_ITEMS_PER_PAGE } from "config/conf";
import { ITableProps } from "components/common/table/ITable";
import { useChecks } from "hooks/useChecks";
import { calculatePageFilters } from "utils/pageUtils";

import Checkbox from "components/common/checkbox/Checkbox";
import Row from "components/common/row/Row";
import PencilButton from "components/buttons/pencilButton/Pencilbutton";

import { getAllSectors1Api } from "config/apiConf";
import { allSectors1Module } from "config/modulesConf";
import { IAllSectors1Data, IAllSectors1Result } from "containers/sectors1/ISectors";

interface IProps {
    route: IRoute;
    allSectors1?: ISmartState;
    allSectors1Actions?: ISmartAction;
}

const AllSectors1Tabs = (props: IProps) => {
  const { addParams } = useRoute();
  const [tblLoader, setTblLoader] = useState(false);
  const [tblData, setTblData] = useState() as any;
  const [crrPage, setCrrPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_ITEMS_PER_PAGE);
  const [tableSorter, setTableSorter] = useState({}) as any;
  const [tableFilters, setTableFilters] = useState({}) as any;
  const data: IAllSectors1Data = props.allSectors1?.data;
  const uc = useChecks();

  useEffect(() => {
    props.allSectors1Actions?.reset();
    
    getData(crrPage, pageSize, tableFilters, tableSorter);

    return () => {
      props.allSectors1Actions?.reset();
    };

    // eslint-disable-next-line
  }, []);

  const getData = (cPage: number, pageSize: number, tblFilters: any, tblSorter: any) => {
    setCrrPage(cPage);
    setPageSize(pageSize);
    setTblLoader(true);

    const filter: any = { 
      pageNumber: cPage, 
      pageSize: pageSize,
    };

    calculatePageFilters(filter, tblFilters, tableFilters, setTableFilters);
    calculatePageFilters(filter, tblSorter, tableSorter, setTableSorter);

    props.allSectors1Actions?.httpCall(addParams(getAllSectors1Api, filter));
  };

  useEffect(() => {
    if (props.allSectors1?.opCompleted) {
      //Add Ids for uc
      data.results.forEach((sector1, index) => {
        sector1.id = index + 1;
      });

      setTblData(data.results);
      setTblLoader(false);
      uc.newData(data.results);
    }
    // eslint-disable-next-line
  }, [props.allSectors1?.opCompleted]);

  useEffect(() => {
    if (props.allSectors1?.errors) {
      setTblLoader(false);
    }
    // eslint-disable-next-line
  }, [props.allSectors1?.errors]);

  const disabled = false;
  return (
    <PageContent tabsMode={true}>
      <Row justifyContent="space-between" height="52px">
      </Row>
      <Table
        totalEntries={data?.totalEntries}
        onChange={(cp: number, ps: number) => getData(cp, ps, tableFilters, tableSorter)}
        onHeaderFilterChange={(colId: any, value: any) => getData(1, pageSize, { [colId]: value }, tableSorter)}
        onSort={(st: any) => getData(crrPage, pageSize, tableFilters, st)}
        sortBy={tableSorter}
        maxPages={data?.pageCount}
        loader={tblLoader}
        data={tblData}
        crrPage={crrPage}
        selectedRows={uc.checksData}
        columns={[
          {
            headerFilterProps: {
              type: "number",
            },
            Header: "x",
            hideHeaderLabel: true,
            headerFilter: false,
            columnWidth: 30,
            headerComponent: <Checkbox disabled={disabled} checked={uc.checksData.all} onChange={(val: boolean) => uc.calculate(val)} />,
            Cell: (props: ITableProps<IAllSectors1Result>) => (
              <Checkbox
                disabled={disabled}
                onChange={(val: boolean) => uc.calculate(val, props.row.original.id)}
                checked={uc.checksData[props.row.original.id]}
              />
            ),
          },
          {
            Header: "Secteur",
            accessor: nameOf((n: IAllSectors1Result) => n.sector1),
            Cell: (props: ITableProps<IAllSectors1Result>) =>
              (props.row.original as IAllSectors1Result).sector1
                ? (props.row.original as IAllSectors1Result).sector1
                : null,
          },
          {
            headerFilter: false,
            Header: "Actions",
            columnWidth: 60,
            center: true,
            sortable: false,
            Cell: (props: ITableProps<IAllSectors1Result>) => (
              <Row center={true}>
                <PencilButton disabled={true} param={props.row.original}  />
              </Row>
            ),
          },
        ]}
      />
    </PageContent>
  );
};

export default smartConnect(
    [allSectors1Module],
    [allSectors1Module]
)(AllSectors1Tabs);
