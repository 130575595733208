import React, { useState, useEffect } from "react";
import PageContent from "pageWrapper/pageContent/PageContent";
import { IRoute } from "interfaces/IRoute";
import { ISmartState, ISmartAction } from "interfaces/ISmart";
import { smartConnect } from "app/appUtils";

import SelectInterventionPoint from "containers/interventionPoints/SelectInterventionPoint";
import InterventionPointAvailability from "containers/interventionPoints/InterventionPointAvailability";

import { allAnimationsRoute } from "config/routeConf";
import { interventionPointModule, interventionDateModule, animationModule, userSettingsModule } from "config/modulesConf";
import { IAllInterventionPointsResult } from "containers/interventionPoints/IInterventionPoint"
import { getInterventionPointApi } from "config/apiConf";

import Form from "components/common/form/Form";
import Field from "components/common/field/Field"

import NewAnimationPopup from "./popups/NewAnimationPopup"
import { IUserSettingsData } from "interfaces/modules/IUserSettings";
import { useRoute } from "hooks/useRoute";

interface IProps {
  route: IRoute;
  interventionPoint?: ISmartState;
  interventionPointActions?: ISmartAction;
  interventionDate?: ISmartState;
  interventionDateActions?: ISmartAction;
  animation?: ISmartState;
  animationActions?: ISmartAction;
  userSettings?: ISmartState;
}

const NewAnimation = (props: IProps) => {
  const [calendarVisible, setCalendarVisible] = useState(false);
  const { getRouteFromParams } = useRoute();
  const [popup, setPopup] = useState(false);
  const [forcedId, setForcedId] = useState<number>();
  const userSettingsData: IUserSettingsData = props.userSettings?.data;
  const campaignName: string = userSettingsData.campaignName;

  useEffect(() => {
    const currentURL = new URL(window.location.toString());
    const paramId = currentURL.pathname.split(`${props.route.path}/`)[1];
    if (paramId && parseInt(paramId)) {
      setForcedId(parseInt(paramId));
    }
    setCalendarVisible(false);
    props.interventionDateActions?.reset();
    props.interventionPointActions?.reset();
    return () => {
      props.interventionDateActions?.reset();
      props.interventionPointActions?.reset();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.animation?.opCompleted) {
      setPopup(false);
      props.interventionPointActions?.httpCall(getRouteFromParams(getInterventionPointApi, { id: props.interventionPoint?.data?.id }));
    }
    // eslint-disable-next-line
  }, [props.animation?.opCompleted]);

  useEffect(() => {
    if (props.interventionPoint?.opCompleted) {
      setCalendarVisible(true);
    }
    // eslint-disable-next-line
  }, [props.interventionPoint?.opCompleted]);

  useEffect(() => {
    if (props.interventionPoint?.errors) {
      setCalendarVisible(false);
    }
    // eslint-disable-next-line
  }, [props.interventionPoint?.errors]);

  const onSelectInterventionPoint = (interventionPoint: IAllInterventionPointsResult) => {
    if (interventionPoint === null)
      setCalendarVisible(false);
    else
      props.interventionPointActions?.httpCall(getRouteFromParams(getInterventionPointApi, { id: interventionPoint?.id }));
    //props.interventionPointActions?.functionCall( () => { return { data: interventionPoint } } );
  };

  const onSelectDate = (date: Date) => {
    props.interventionDateActions?.functionCall(() => { return { data: date } });
    setPopup(true);
  };

  return (
    <PageContent
      generalLoader={false}
      title={`Nouvelle Animation (${campaignName})`}
      backTo={allAnimationsRoute.path}
    >
      <Form
        tabsMode={true}
        loader={false}
      // actions={[
      //   <SaveButton disabled={disabledBtns} type={"Soumettre"} onClick={smartForm.submit} label={"Sauvegarder"} />,
      // ]}
      >
        <Field label="Point d'intervention" borderBox={true} spacing={true} content={
          <SelectInterventionPoint onSelectInterventionPoint={onSelectInterventionPoint} forcedId={forcedId} />
        } />

        <Field label="Calendrier" borderBox={true} spacing={true} isVisible={calendarVisible} content={
          <InterventionPointAvailability onSelectDate={onSelectDate} />
        } />

        {popup && <NewAnimationPopup onClose={setPopup} />}

      </Form>
    </PageContent>
  );
};

export default smartConnect([userSettingsModule,
  interventionPointModule, interventionDateModule, animationModule
], [interventionPointModule, interventionDateModule, animationModule])(NewAnimation);
