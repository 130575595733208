import { useState } from "react";
import { arrayCut } from "utils/arrayUtils";
import { deepClone } from "utils/cloneUtils";
import { iterateObject } from "utils/objectUtils";

interface IProps {
  visibleItems: number;
}

export const useMore = (props: IProps) => {
  const [columns, setColumns] = useState({ expanded: false }) as any;
  const [backup, setBackup] = useState({ expanded: false }) as any;

  const setValues = (obj: any, id: string, key: string) => {
    const newData: any = { expanded: false };
    if (obj) {
      obj.forEach((element: any) => {
        if (element[id] && element[key]) {
          newData[element[id]] = {
            expanded: false,
            values: element[key],
          };
          const bk = backup[element[id]];
          if (bk) {
            newData[element[id]] = {
              expanded: bk.expanded,
              values: deepClone(bk.values),
            };
          }
        }
      });
    }
    setColumns(newData);
  };

  const getValues = (key: any) => {
    const row = columns[key];
    if (row) return row.expanded ? row.values : arrayCut(row.values, 0, props.visibleItems);

    return [];
  };

  const getRowExpanded = (key: any) => {
    const row = columns[key];
    if (row) return row.expanded;
    return false;
  };

  const getExpanded = () => {
    return columns.expanded;
  };

  const setRowExpanded = (key: string) => {
    const newData = deepClone(columns);
    const row = newData[key];
    if (row) {
      row.expanded = !row.expanded;
    }
    let allExpanded = true;
    iterateObject(newData, (k: any, value: any) => {
      if (value["expanded"] !== undefined && value["expanded"] !== true) {
        allExpanded = false;
      }
    });
    newData.expanded = allExpanded;
    setColumns(newData);
  };

  const setExpanded = () => {
    const newData = deepClone(columns);
    newData.expanded = !newData.expanded;
    iterateObject(newData, (key: any, value: any) => {
      if (key !== "expanded" && newData[key]) {
        newData[key].expanded = newData.expanded;
      }
    });
    setColumns(newData);
  };

  const reset = () => {
    setBackup(deepClone(columns));
    /* do not delete until the code is safe
    const newData = deepClone(columns);
    newData.expanded = false;
    iterateObject(newData, (key: any, value: any) => {
      if (key !== "expanded" && newData[key]) {
        newData[key].expanded = false;
      }
    });
    setColumns(newData);
    */
  };

  return {
    setValues,
    getValues,
    getExpanded,
    getRowExpanded,
    setRowExpanded,
    setExpanded,
    reset,
  };
};
