import React, { useEffect, useState } from "react";
import { useRoute } from "hooks/useRoute";
import PageContent from "pageWrapper/pageContent/PageContent";
import { IRoute } from "interfaces/IRoute";
import { nameOf } from "utils/nameOfUtils";
import { smartConnect } from "app/appUtils";
import { ISmartState, ISmartAction } from "interfaces/ISmart";

import Table from "components/common/table/Table";
import { TABLE_ITEMS_PER_PAGE, MORE_VISIBLE_ITEMS } from "config/conf";
import { ITableProps } from "components/common/table/ITable";
import { useChecks } from "hooks/useChecks";
import { calculatePageFilters } from "utils/pageUtils";
import { useMore } from "hooks/useMore";
import { createComponent } from "utils/componentUtils";

import MoreButton from "components/buttons/moreButton/MoreButton";
import ExpandButton from "components/buttons/expandButton/ExpandButton";
import Check from "components/common/check/Check";
import Row from "components/common/row/Row";
import NeuroButton from "components/buttons/neuroButton/NeuroButton";
import CircleButton from "components/buttons/circleButton/CircleButton";
import Switch from "components/common/switch/Switch"
import CreateInvitationPopup from "./popup/CreateInvitationPopup";
import { FaPlusCircle, FaGlobe, FaUserCog, FaChartPie } from "react-icons/fa";

import { getAllUsersApi } from "config/apiConf";
import { userSettingsModule, allUsersModule, createInvitationModule, toastModule, updateUserModule, updateUserQuotaModule, updateUserSectors1Module } from "config/modulesConf";
import { IAllUsersData, IAllUsersResult } from "containers/users/IUsers";
import { IToast } from "components/common/toast/Toast";
import UpdateUserSectors1Popup from "./popup/UpdateUserSectors1Popup";
import UpdateUserPopup from "./popup/UpdateUserPopup";
import UpdateUserQuotaPopup from "./popup/UpdateUserQuotaPopup";
import { AreaManager, BusinessLeader } from "config/permsConf";
import { IUserSettingsData } from "interfaces/modules/IUserSettings";


interface IProps {
    route: IRoute;
    allUsers?: ISmartState;
    allUsersActions?: ISmartAction;
    createInvitation?: ISmartState;
    updateUserSectors1?: ISmartState;
    updateUser?: ISmartState;
    updateUserQuota?: ISmartState;
    toastActions?: ISmartAction;
    userSettings?: ISmartState;
}

const AllUsersTabs = (props: IProps) => {
  const { addParams } = useRoute();
  const [invitationPopup, setInvitationPopup] = useState(false);
  const [sectors1Popup, setSectors1Popup] = useState<IAllUsersResult | null>();
  const [rolesPopup, setRolesPopup] = useState<IAllUsersResult | null>();
  const [quotaPopup, setQuotaPopup] = useState<IAllUsersResult | null>();
  const umSectors1 = useMore({ visibleItems: MORE_VISIBLE_ITEMS });
  const [tblLoader, setTblLoader] = useState(false);
  const [tblData, setTblData] = useState() as any;
  const [crrPage, setCrrPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_ITEMS_PER_PAGE);
  const [tableSorter, setTableSorter] = useState({}) as any;
  const [tableFilters, setTableFilters] = useState({}) as any;
  const userSettingsData: IUserSettingsData = props.userSettings?.data;
  const data: IAllUsersData = props.allUsers?.data;
  const uc = useChecks();

  useEffect(() => {
    props.allUsersActions?.reset();
    
    getData(crrPage, pageSize, tableFilters, tableSorter);

    return () => {
      props.allUsersActions?.reset();
    };

    // eslint-disable-next-line
  }, []);

  const getData = (cPage: number, pageSize: number, tblFilters: any, tblSorter: any) => {
    setCrrPage(cPage);
    setPageSize(pageSize);
    setTblLoader(true);

    const filter: any = { 
      pageNumber: cPage, 
    };

    calculatePageFilters(filter, tblFilters, tableFilters, setTableFilters);
    calculatePageFilters(filter, tblSorter, tableSorter, setTableSorter);

    props.allUsersActions?.httpCall(addParams(getAllUsersApi, filter));
  };

  useEffect(() => {
    if (props.allUsers?.opCompleted) {
      setTblData(data.results);
      setTblLoader(false);
      uc.newData(data.results);

      umSectors1.setValues(data.results, "id", "sectors1");
    }
    // eslint-disable-next-line
  }, [props.allUsers?.opCompleted]);

  useEffect(() => {
    if (props.allUsers?.errors) {
      setTblLoader(false);
    }
    // eslint-disable-next-line
  }, [props.allUsers?.errors]);

  useEffect(() => {
    if (props.createInvitation?.opCompleted) {
      const toast: IToast = { label: "Invitation envoyée", type: "success" }
      props.toastActions?.functionCall(() => { return { data: toast }});
      setInvitationPopup(false);
      getData(crrPage, pageSize, tableFilters, tableSorter);
    }
    // eslint-disable-next-line
  }, [props.createInvitation?.opCompleted]);

  useEffect(() => {
    if (props.updateUserSectors1?.opCompleted) {
      setSectors1Popup(null);
      getData(crrPage, pageSize, tableFilters, tableSorter);
    }
    // eslint-disable-next-line
  }, [props.updateUserSectors1?.opCompleted]);

  useEffect(() => {
    if (props.updateUser?.opCompleted) {
      setRolesPopup(null);
      getData(crrPage, pageSize, tableFilters, tableSorter);
    }
    // eslint-disable-next-line
  }, [props.updateUser?.opCompleted]);

  useEffect(() => {
    if (props.updateUserQuota?.opCompleted) {
      setQuotaPopup(null);
      getData(crrPage, pageSize, tableFilters, tableSorter);
    }
    // eslint-disable-next-line
  }, [props.updateUserQuota?.opCompleted]);

  const disabledActions = userSettingsData.role === BusinessLeader || userSettingsData.role === AreaManager;
  return (
    <PageContent tabsMode={true} actions={[]}>
      <Row justifyContent="space-between">
        <NeuroButton disabled={disabledActions} color="green" label={"Utilisateur"} onClick={setInvitationPopup} param={true} tooltip="Inviter un utilisateur" content={<FaPlusCircle size={20} />}/>
      </Row>
      <Table
        totalEntries={data?.totalEntries}
        onChange={(cp: number, ps: number) => getData(cp, ps, tableFilters, tableSorter)}
        onHeaderFilterChange={(colId: any, value: any) => getData(1, pageSize, { [colId]: value }, tableSorter)}
        onSort={(st: any) => getData(crrPage, pageSize, tableFilters, st)}
        sortBy={tableSorter}
        maxPages={data?.pageCount}
        loader={tblLoader}
        data={tblData}
        crrPage={crrPage}
        selectedRows={uc.checksData}
        columns={[
          {
            Header: "Id",
            columnWidth: 60,
            accessor: nameOf((n: IAllUsersResult) => n.id),
            Cell: (props: ITableProps<IAllUsersResult>) =>
              (props.row.original as IAllUsersResult).id
                ? (props.row.original as IAllUsersResult).id
                : null,
          },
          {
            Header: "Email",
            accessor: nameOf((n: IAllUsersResult) => n.email),
            Cell: (props: ITableProps<IAllUsersResult>) =>
              (props.row.original as IAllUsersResult).email
                ? (props.row.original as IAllUsersResult).email
                : null,
          },
          {
            Header: "Noms",
            accessor: nameOf((n: IAllUsersResult) => n.fullname),
            Cell: (props: ITableProps<IAllUsersResult>) =>
              (props.row.original as IAllUsersResult).fullname
                ? (props.row.original as IAllUsersResult).fullname
                : null,
          },
          {
            Header: "Role",
            accessor: nameOf((n: IAllUsersResult) => n.role),
            Cell: (props: ITableProps<IAllUsersResult>) =>
              (props.row.original as IAllUsersResult).role
                ? (props.row.original as IAllUsersResult).role
                : null,
          },
          {
            Header: "Secteur(s)",
            accessor: nameOf((n: IAllUsersResult) => n.sectors1),
            sortable: false,
            headerFilter: false,
            headerActions: [
                <ExpandButton
                  expanded={umSectors1.getExpanded()}
                  onClick={umSectors1.setExpanded}
                />,
              ],
              Cell: (props: ITableProps<IAllUsersResult>) =>
              createComponent(
                umSectors1.getValues(props.row.original.id),
                props.value.length > MORE_VISIBLE_ITEMS ? (
                  <MoreButton
                    expanded={umSectors1.getRowExpanded(
                      props.row.original.id
                    )}
                    param={props.row.original.id}
                    onClick={umSectors1.setRowExpanded}
                  />
                ) : null
              ),
          },
          {
            Header: "Quota",
            headerFilter: false,
            accessor: nameOf((n: IAllUsersResult) => n.quota),
            Cell: (props: ITableProps<IAllUsersResult>) =>
              (props.row.original as IAllUsersResult).quota
                ? (props.row.original as IAllUsersResult).quota
                : null,
          },
          {
            Header: "Status",
            accessor: nameOf((n: IAllUsersResult) => n.isActive),
            columnWidth: 1,
            Filter: Switch,
            filterProps: { checked: tableFilters.isActiv, nullable: true, },
            Cell: (props: ITableProps<IAllUsersResult>) => <Row center={true} content={<Check checked={(props.row.original as IAllUsersResult).isActive} />} />,
          },
          {
            headerFilter: false,
            Header: "Actions",
            columnWidth: 60,
            center: true,
            sortable: false,
            Cell: (props: ITableProps<IAllUsersResult>) => (
              <Row center={true}>
                <CircleButton color="blue" disabled={userSettingsData.role === BusinessLeader || (userSettingsData.role === AreaManager && (props.row.original as IAllUsersResult).role !== BusinessLeader)}
                 param={props.row.original} onClick={setSectors1Popup} content={<FaGlobe size={20}/>} tooltip="Modifier Secteurs" />
                <CircleButton color="purple" disabled={userSettingsData.role === BusinessLeader || userSettingsData.role === AreaManager} 
                param={props.row.original} onClick={setRolesPopup} content={<FaUserCog size={20}/>} tooltip="Modifier Role"/>
                <CircleButton color="green" disabled={userSettingsData.role === BusinessLeader || (props.row.original as IAllUsersResult).role !== BusinessLeader} param={props.row.original} onClick={setQuotaPopup} content={<FaChartPie size={20}/>} tooltip="Modifier Quota"/>
              </Row>
              
            ),
          },
        ]}
      />
      {invitationPopup && <CreateInvitationPopup onClose={setInvitationPopup} />}
      {sectors1Popup && <UpdateUserSectors1Popup onClose={setSectors1Popup} user={sectors1Popup}/>}
      {rolesPopup && <UpdateUserPopup onClose={setRolesPopup} user={rolesPopup}/>}
      {quotaPopup && <UpdateUserQuotaPopup onClose={setQuotaPopup} user={quotaPopup}/>}
    </PageContent>
  );
};

export default smartConnect(
  [ userSettingsModule, allUsersModule, createInvitationModule, updateUserSectors1Module, updateUserModule, updateUserQuotaModule], 
  [allUsersModule, toastModule])(AllUsersTabs);
