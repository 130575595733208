import React, { useEffect } from "react";
import { deepClone } from "utils/cloneUtils";
import { nameOf } from "utils/nameOfUtils";
import { useRoute } from "hooks/useRoute";

import { ISmartAction, ISmartState } from "interfaces/ISmart";

import { smartConnect } from "app/appUtils";
import Form from "components/common/form/Form";
import { IPopupProps } from "components/common/popup/IPopupProps";
import Popup from "components/common/popup/Popup";

import { updateUserApi } from "config/apiConf";
import { updateUserQuotaModule } from "config/modulesConf";
import NeuroButton from "components/buttons/neuroButton/NeuroButton";
import { IAllUsersResult, IUser } from "../IUsers";
import { useTranslate } from "services/translationService";
import * as lngConf from "config/lngConf";
import { smartNumberValidator, smartValidationSchema, useSmartForm } from "hooks/useSmartForm";
import Input from "components/common/input/Input";
import { INPUT_TYPE_NUMBER } from "config/conf";

interface IProps extends IPopupProps {
  user: IAllUsersResult;
  updateUserQuota?: ISmartState;
  updateUserQuotaActions?: ISmartAction;
}

const UpdateUserQuotaPopup = (props: IProps) => {
  const { t } = useTranslate();
  const { getRouteFromParams } = useRoute();

  useEffect(() => {
    props.updateUserQuotaActions?.reset();
    return () => {
      props.updateUserQuotaActions?.reset();
    };
    // eslint-disable-next-line
  }, []);
    
const update = (values: any) => {
    const newValues = deepClone(values);
    props.updateUserQuotaActions?.httpCall(getRouteFromParams(updateUserApi, {id: props.user?.id}), {id: props.user?.id, quota: newValues.quota });
  };

const smartForm = useSmartForm({
    initialValues: {
       quota: props.user?.quota
    },
    validationSchema: smartValidationSchema({
      quota: smartNumberValidator().required(t(lngConf.ERR_REQ_FIELD))
    }),
    onSubmit: (values: any) => {
        update(values);
    },
    onChanged: () => {
    },
  });

  const quota = nameOf((n: IUser) => n.quota);
  return (
    <Popup scrollableContent {...props} height='250px' bigSize={true} title={"Quota de l'utilisateur"}>
      <Form
        actions={[
          <NeuroButton color="green" size={'normal'} onClick={smartForm.submit} label={"Sauvegarder"} disabled={false} />
        ]}>
          <Input
          value={smartForm.getValue(quota)}
          type={INPUT_TYPE_NUMBER}
          required={true}
          onNativeChange={smartForm.change}
          name={quota}
          error={smartForm.getError(quota)}
          label={t(lngConf.LABEL_QUOTA)}
        />
      </Form>
    </Popup>
  );
};

export default smartConnect(
  [updateUserQuotaModule],
  [updateUserQuotaModule]
)(UpdateUserQuotaPopup);
