import React, { useState } from "react";
import moment from 'moment';
import PageContent from "pageWrapper/pageContent/PageContent";
import { IRoute } from "interfaces/IRoute";
import Tabs from "components/common/tabs/Tabs";
import { useParams } from "react-router-dom";
import { IParams } from "interfaces/IParams";
import { ITabItem } from "components/common/tabs/ITabs";
import { nameOf } from "utils/nameOfUtils";
import { smartConnect } from "app/appUtils";
import { ISmartState } from "interfaces/ISmart";
import { BusinessLeader } from "config/permsConf";

import NeuroButton from "components/buttons/neuroButton/NeuroButton";
import DateSelector from "components/common/dateSelector/DateSelector";
import Row from "components/common/row/Row";
import Label from "components/common/label/Label";
import { FaPlusCircle } from "react-icons/fa";
import Switch from "components/common/switch/Switch";

import { IAllAnimationsFilters } from "./IAnimations";
import { animationRoute } from "config/routeConf";
import AllAnimationsCalendar from "./tabs/AllAnimationsCalendar";
import AllAnimationsList from "./tabs/AllAnimationsList";
import "./animations.scss"
import { allAnimationsModule, userSettingsModule } from "config/modulesConf";
import { IUserSettingsData } from "interfaces/modules/IUserSettings";
import BookingStatistics from "components/chart/BookingStatistics";
import Column from "components/common/column/Column";

interface IProps {
  route: IRoute;
  quota?: ISmartState;
  userSettings?: ISmartState;
}

const AllAnimationsTabs = (props: IProps) => {
  const params = useParams() as IParams;
  const [includeBackup, setIncludeBackup] = useState(false) as any;
  const [dateFrom, setDateFrom] = useState(new Date()) as any;
  const [dateTo, setDateTo] = useState(moment().add(2, 'month').toDate()) as any;
  const userSettingsData: IUserSettingsData = props.userSettings?.data;
  const sector1 : string = userSettingsData.role !== BusinessLeader ? "Multi" : userSettingsData.sectors1.length < 1 ? "Non Défini" : userSettingsData.sectors1[0];
  const quota : number = userSettingsData.quota;
  const campaignName : string = userSettingsData.campaignName;

  return (
    <PageContent
      tabsMode={true}
      actions={ userSettingsData.role === BusinessLeader &&[
        <Row spaceBetween={true} middle={true} fullSize={true} marginTop={'-40px'} marginBottom={'-40px'}>
            <Label name={`Secteur : ${sector1} `} bold={true} labelSize="big"/>
            <Column alignItems='center'>
              <Label name={`${campaignName}`} bold={true} labelSize="normal" spaceBetween={true}/>
              <Label name={`Objectif :  ${quota}`} bold={true} labelSize="normal" spaceBetween={true}/>
            </Column>
            <BookingStatistics />
        </Row>]}
    >
      <Row spaceBetween alignItems='flex-end'>
        <NeuroButton color="green" label={"Animation"} to={animationRoute.path} newTab={false} tooltip="Nouvelle Animation" content={<FaPlusCircle size={20} />}/>
        <Row justifyContent='center'>
          <Label name="Du" bold={true} labelSize="normal"/>
          <DateSelector
            name={nameOf((n: IAllAnimationsFilters) => n.dateFrom)}
            onChange={(filter: Date) => setDateFrom(filter)}
            dateFormat={"dd/MM/yyyy"}
            value={dateFrom}
          />
          <Label name="Au" bold={true} labelSize="normal"/>
          <DateSelector fullSize={true}
            name={nameOf((n: IAllAnimationsFilters) => n.dateTo)}
            onChange={(filter: Date) => setDateTo(filter)}
            dateFormat={"dd/MM/yyyy"}
            value={dateTo}
          />
        </Row>
        <Row minWidth='200px'>
          <Row justifyContent='center'>
            <Label name="Campagnes Back-up" bold={true} labelSize="normal"/>
            <Switch
                checked={includeBackup}
                onChange={(value: boolean) => setIncludeBackup(value)}
              />
          </Row>
        </Row>
      </Row>
      <Tabs
        showTabs={false}
        firstOnly={params.id === undefined && true}
        items={[
          {
            label: "Liste",
            content: AllAnimationsList,
            path: "liste",
            props: {
              dateFrom,
              dateTo,
              includeBackup
            }
          } as ITabItem,
          {
            label: "Calendrier",
            content: AllAnimationsCalendar,
            path: "calendrier",
          } as ITabItem,
        ]}
      />
    </PageContent>
  );
};

export default smartConnect([allAnimationsModule, userSettingsModule], [allAnimationsModule])(AllAnimationsTabs);
