import { saveTypes } from "app/appConf";
import { IModule } from "interfaces/IModule";

export const userSettingsModule: IModule = {
  name: "userSettings",
};

export const watcherModule: IModule = {
  name: "watcher",
};

export const toastModule: IModule = {
  name: "toast",
};

export const loginModule: IModule = {
  name: "login",
  saveTo:saveTypes.LOCAL_STORAGE,
};

export const homeModule: IModule = {
  name: "home",
};

export const allImportsModule: IModule = {
  name: "allImports",
};

export const allImportsActionsModule: IModule = {
  name: "allImportsActions",
};

//Sectors1
export const allSectors1Module: IModule = {
  name: "allSectors1",
};

//Sectors3
export const sectors3DropdownModule: IModule = {
  name: "sectors3Dropdown",
};

//Statistics
export const sector3StatisticsModule: IModule = {
  name: "sector3Statistics",
};

//Users
export const allUsersModule: IModule = {
  name: "allUsers",
};

export const userSectors1Module: IModule = {
  name: "userSectors1",
};

export const createInvitationModule: IModule = {
  name: "createInvitation",
};

export const userRoleModule: IModule = {
  name: "userRole",
};

//update User
export const updateUserModule: IModule = {
  name: "updateUser",
};

export const updateUserSectors1Module: IModule = {
  name: "updateUserSectors1",
};

export const updateUserQuotaModule: IModule = {
  name: "updateUserQuota",
};

//Animations
export const allAnimationsModule: IModule = {
  name: "allAnimations",
};

export const allAnimationsTmpModule: IModule = {
  name: "allAnimationsTmp",
};

export const animationModule: IModule = {
  name: "animation",
};

export const animationTmpModule: IModule = {
  name: "animationTmp",
};

export const cancellationRequestModule: IModule = {
  name: "cancellationRequest",
};

//intervention Points
export const allInterventionPointsModule: IModule = {
  name: "allInterventionPoints",
};

export const interventionPointsNextAvailabilitiesModule: IModule = {
  name: "interventionPointsNextAvailabilities",
};

export const interventionPointsTmpModule: IModule = {
  name: "interventionPointsTmp",
};

export const interventionPointAvaibilityModule: IModule = {
  name: "interventionPointAvaibility",
};

export const availablePromotersModule: IModule = {
  name: "availablePromoters",
};

export const availablePromotersTmpModule: IModule = {
  name: "availablePromotersTmp",
};

export const updateInterventionPointModule: IModule = {
  name: "updateInterventionPoint",
};

//Selected 
export const interventionPointModule: IModule = {
  name: "interventionPoint",
};

export const interventionDateModule: IModule = {
  name: "interventionDate",
};

export const bookingStatisticsModule: IModule = {
  name: "bookingStatistics",
};

//Roles
export const rolesDropdownModule: IModule = {
  name: "rolesDropdown",
};

export const allRolesModule: IModule = {
  name: "allRoles",
};

//Campaigns
export const campaignsDropdownModule: IModule = {
  name: "campaignsDropdown",
};