import React from "react";
import { makeClassName, makeStyleProperties } from "utils/componentUtils";
import { IDefaultProps } from "interfaces/IDefaultProps";
import "./row.scss";

interface IProps extends IDefaultProps {
  children?: any;
  content?: any;
  sameWidth?: boolean;
}

const Row = (props: IProps) => {
  return (
    <div className={makeClassName("row" + (props.sameWidth === true ? " sameWidth" : ""), props)} style={makeStyleProperties(props, props.style)}>
      {props.content}
      {props.children}
    </div>
  );
};

export default Row;
