import moment from "moment";
import React, { useEffect, useState } from "react";
import { useChecks } from "hooks/useChecks";
import { useMore } from "hooks/useMore";
import { useRoute } from "hooks/useRoute";
import { useHistory } from "react-router-dom";

import { smartConnect } from "app/appUtils";
import { getInterventionPointByAvailabilityApi } from "config/apiConf";
import { MORE_VISIBLE_ITEMS } from "config/conf";
import { TABLE_ITEMS_PER_PAGE } from "config/conf";
import { interventionPointsNextAvailabilitiesModule } from "config/modulesConf";
import { IRoute } from "interfaces/IRoute";
import { ISmartState, ISmartAction } from "interfaces/ISmart";
import { createComponent } from "utils/componentUtils";
import { nameOf } from "utils/nameOfUtils";
import { calculatePageFilters } from "utils/pageUtils";
import { animationRoute } from "config/routeConf";

import { IInterventionPointNextAvailabilities, IInterventionPointNextAvailabilitiesData } from "../IInterventionPointNextAvailabilities";
import CircleButton from "components/buttons/circleButton/CircleButton";
import ExpandButton from "components/buttons/expandButton/ExpandButton";
import MoreButton from "components/buttons/moreButton/MoreButton";
import Form from "components/common/form/Form";
import Row from "components/common/row/Row";
import Table from "components/common/table/Table";
import { ITableProps } from "components/common/table/ITable";
import { FaPlus } from "react-icons/fa";

interface IProps {
  route: IRoute;
  dateFrom: Date;
  dateTo: Date;
  interventionPointsNextAvailabilities?: ISmartState;
  interventionPointsNextAvailabilitiesActions?: ISmartAction;
}

const InterventionPointsNextAvailabilitiesList = (props: IProps) => {
  const { addParams } = useRoute();
  const uc = useChecks({ single: true });
  const [tableLoader, setTableLoader] = useState(false);
  const [tableData, setTableData] = useState<Array<IInterventionPointNextAvailabilities>>();
  const [tableFilters, setTableFilters] = useState({}) as any;
  const [tableSorter, setTableSorter] = useState({}) as any;
  const umNextAvailabilities = useMore({ visibleItems: MORE_VISIBLE_ITEMS });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_ITEMS_PER_PAGE);
  const data: IInterventionPointNextAvailabilitiesData = props.interventionPointsNextAvailabilities?.data;
  const history = useHistory();

  useEffect(() => {
    props.interventionPointsNextAvailabilitiesActions?.reset();
    getData(currentPage, pageSize, tableFilters, tableSorter);

    return () => {
      props.interventionPointsNextAvailabilitiesActions?.reset();
    };

    // eslint-disable-next-line
  }, [props.dateFrom, props.dateTo]);

  useEffect(() => {
    if (props.interventionPointsNextAvailabilities?.opCompleted) {
      formatData();
      setTableData(data.results as unknown as Array<IInterventionPointNextAvailabilities>);
      setTableLoader(false);
      umNextAvailabilities.setValues(data.results, "interventionPointId", "promoterAvailabilities");
    }
    // eslint-disable-next-line
  }, [props.interventionPointsNextAvailabilities?.opCompleted]);

  useEffect(() => {
    if (props.interventionPointsNextAvailabilities?.errors) {
      setTableLoader(false);
    }
  }, [props.interventionPointsNextAvailabilities]);

  const getData = (pageNumber: number, pageSize: number, filter: any, sorter: any) => {
    setCurrentPage(currentPage);
    setPageSize(pageSize);
    setTableLoader(true);

    updateFilterName(filter);
    const filterData = {
      pageNumber,
      pageSize,
      dateFrom: moment(props.dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(props.dateTo).format('YYYY-MM-DD')
    };

    calculatePageFilters(filterData, filter, tableFilters, setTableFilters);
    calculatePageFilters(filterData, sorter, tableSorter, setTableSorter);

    props.interventionPointsNextAvailabilitiesActions?.httpCall(addParams(getInterventionPointByAvailabilityApi, filterData));
  }

  const updateFilterName = (filter: any) => {
    if (filter.interventionPointId) {
      filter.id = filter.interventionPointId;
      delete filter.interventionPointId;
    }
  }

  const formatData = () => {
    data.results.forEach(x => {
      const availableDates = new Set<string>();

      x.promoterAvailabilities.forEach(promoterAvailability => {
        promoterAvailability.availabilities.forEach(availability => availableDates.add(moment(availability).format('DD-MM-YYYY')));
      });

      //@ts-ignore
      x.promoterAvailabilities = [...availableDates];
      return data;
    });
  }

  const onRowSelect = (row: any) => {
    uc.calculate(true, row.original.interventionPointId);
    history.push(`${animationRoute.path}/${row.original.interventionPointId}`)
  };

  return (
    <Form
      fullHeight={true}
      tabsMode={true}
      noWrap={true}
    >
      <Table
        totalEntries={data?.totalEntries}
        onChange={(cp: number, ps: number) => getData(cp, ps, tableSorter, tableFilters)}
        onHeaderFilterChange={(colId: any, value: any) => getData(1, pageSize, { [colId]: value }, tableSorter)}
        onSort={(st: any) => getData(currentPage, pageSize, tableFilters, st)}
        sortBy={tableSorter}
        maxPages={data?.pageCount}
        crrPage={currentPage}
        loader={tableLoader}
        onRowSelect={onRowSelect}
        data={tableData}
        columns={[
          {
            Header: "Id",
            columnWidth: 1,
            columnMinWidth: 50,
            center: true,
            accessor: nameOf((n: IInterventionPointNextAvailabilities) => n.interventionPointId),
            Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) =>
              <Row center={true}>
                <p>
                  {(props.row.original as IInterventionPointNextAvailabilities).interventionPointId
                    ? (props.row.original as IInterventionPointNextAvailabilities).interventionPointId
                    : null}
                </p>
              </Row>

          },
          {
            Header: "POS",
            columnWidth: 1,
            columnMinWidth: 50,
            center: true,
            accessor: nameOf((n: IInterventionPointNextAvailabilities) => n.codePos),
            Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) =>
              <Row center={true}>
                <p>
                  {(props.row.original as IInterventionPointNextAvailabilities).codePos
                    ? (props.row.original as IInterventionPointNextAvailabilities).codePos
                    : null}
                </p>
              </Row>
          },
          {
            Header: "Zone",
            columnWidth: 1,
            columnMinWidth: 50,
            center: true,
            accessor: nameOf((n: IInterventionPointNextAvailabilities) => n.sector3),
            Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) =>
              <Row center={true}>
                <p>
                  {(props.row.original as IInterventionPointNextAvailabilities).sector3
                    ? (props.row.original as IInterventionPointNextAvailabilities).sector3
                    : null}
                </p>
              </Row>
          },
          {
            Header: "Area",
            columnWidth: 1,
            center: true,
            accessor: nameOf((n: IInterventionPointNextAvailabilities) => n.sector2),
            Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) =>
              <Row center={true}>
                <p>
                  {(props.row.original as IInterventionPointNextAvailabilities).sector2
                    ? (props.row.original as IInterventionPointNextAvailabilities).sector2
                    : null}
                </p>
              </Row>
          },
          {
            Header: "Secteur",
            columnWidth: 1,
            center: true,
            accessor: nameOf((n: IInterventionPointNextAvailabilities) => n.sector1),
            Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) =>
              <Row center={true}>
                <p>
                  {(props.row.original as IInterventionPointNextAvailabilities).sector1
                    ? (props.row.original as IInterventionPointNextAvailabilities).sector1
                    : null}
                </p>
              </Row>
          },
          {
            Header: "Name",
            accessor: nameOf((n: IInterventionPointNextAvailabilities) => n.name),
            Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) =>
              (props.row.original as IInterventionPointNextAvailabilities).name
                ? (props.row.original as IInterventionPointNextAvailabilities).name
                : null,
          },
          {
            Header: "Adresse",
            headerFilter: false,
            accessor: nameOf((n: IInterventionPointNextAvailabilities) => n.address),
            Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) =>
              (props.row.original as IInterventionPointNextAvailabilities).address
                ? (props.row.original as IInterventionPointNextAvailabilities).address
                : null,
          },
          {
            Header: "Disponibilités",
            accessor: nameOf((n: IInterventionPointNextAvailabilities) => n.promoterAvailabilities),
            sortable: false,
            headerFilter: false,
            headerActions: [
              <ExpandButton
                expanded={umNextAvailabilities.getExpanded()}
                onClick={umNextAvailabilities.setExpanded}
              />,
            ],
            Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) =>
              createComponent(
                umNextAvailabilities.getValues(props.row.original.interventionPointId),
                props.value.length > MORE_VISIBLE_ITEMS ? (
                  <MoreButton
                    expanded={umNextAvailabilities.getRowExpanded(
                      props.row.original.interventionPointId
                    )}
                    param={props.row.original.interventionPointId}
                    onClick={umNextAvailabilities.setRowExpanded}
                  />
                ) : null
              ),
          },
          {
            headerFilter: false,
            Header: "Actions",
            columnWidth: 30,
            center: true,
            sortable: false,
            Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) => (
              <Row center={true}>
                <CircleButton
                  color="green"
                  content={<FaPlus size={20} />}
                  tooltip="Nouvelle animation"
                  onClick={() => onRowSelect(props.row)}
                />
              </Row>
            ),
          }
        ]}
      />
    </Form>
  );
};
export default smartConnect(
  [interventionPointsNextAvailabilitiesModule],
  [interventionPointsNextAvailabilitiesModule]
)(InterventionPointsNextAvailabilitiesList);
