import React from "react";
import "./moreButton.scss";
import Button from "components/common/button/Button";
import { IButtonProps } from "components/common/button/IButtonProps";
import { MdExpandLess } from "react-icons/md";
import { IoIosMore } from "react-icons/io";

interface IProps extends IButtonProps {
  expanded?: boolean;
}

const MoreButton = (props: IProps) => {
  return (
    <Button
      styleClass="moreButton"
      content={props.expanded === true ? <MdExpandLess /> : <IoIosMore />}
      tooltip={props.tooltip ? props.tooltip : "développer"}
      {...props}
    />
  );
};

export default MoreButton;
