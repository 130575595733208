import React, { useEffect, useState, useRef, MutableRefObject, CSSProperties } from "react";
import { makeClassName } from "utils/componentUtils";
import { IDefaultProps } from "interfaces/IDefaultProps";
import getBackgroundColor from "./getBackgroundColor";
import "./switch.scss";

interface IProps extends IDefaultProps {
  className?: string;
  onChange: any;
  readOnly?: boolean;
  handleDiameter?: any; 
  checked?: boolean;
  id?: number;
  width: string;
  height: string;
  offHandleColor: string;
  onHandleColor: string;
  offColor: string;
  onColor: string;
  midColor?: string;
  boxShadow?: string;
  activeBoxShadow?: string;
  checkedIcon?: any;
  uncheckedIcon?: any;
  nullable?: boolean;
  children?: JSX.Element[] | JSX.Element;
}

const DeepSwitch = (props: IProps) => {
    const switchRef = useRef() as MutableRefObject<HTMLInputElement>;
    const [isDragging, setDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    var [pos, setPos] = useState(0);
    const [hasOutline, setOutline] = useState(true);

    const height = parseInt(props.height);
    const width = parseInt(props.width);
    const handleDiameter = props.handleDiameter || height - 2;
    const checkedPos = Math.max(
        width - height,
        width - (height + handleDiameter) / 2
      );
    const uncheckedPos = Math.max(0, (height - handleDiameter) / 2);
    const undefinedPos = (checkedPos + uncheckedPos) / 2;

    useEffect(() => {
      const pos = props.checked ? checkedPos : (props.nullable && props.checked == null ? undefinedPos : uncheckedPos);
      setPos(pos);

      // eslint-disable-next-line
    }, [props.checked]);

    const onDragStart = (clientX: any) => {
        switchRef.current.focus();

        let bounds = switchRef.current.getBoundingClientRect();
        let posX = clientX - bounds.left;

        setStartX(posX);
        setOutline(true);
      }
    
      const onDrag = (clientX: any) => {
        let bounds = switchRef.current.getBoundingClientRect();
        let posX = clientX - bounds.left;

        const { checked } = props;
        const startPos = checked ? checkedPos : uncheckedPos;
        const mousePos = startPos + posX - startX;
        // We need this check to fix a windows glitch where onDrag is triggered onMouseDown in some cases
        if (!isDragging && posX !== startX) {
            setDragging(true);
        }
        const newPos = Math.min(
          checkedPos,
          Math.max(uncheckedPos, mousePos)
        );
        // Prevent unnecessary rerenders
        if (newPos !== pos) {
            setPos(newPos);
        }
      }
    
      const onDragStop = (event: any) => {
        let bounds = switchRef.current.getBoundingClientRect();
        let posX = (event.clientX !== undefined) ? event.clientX - bounds.left : event.changedTouches[0].clientX - bounds.left;
        var denominator = bounds.width / ((props.nullable === true) ? 3 : 2);

        if ((props.nullable === true && posX > 2 * denominator)
        || (props.nullable !== true && posX >= denominator)) {
          setPos(checkedPos);
          onChange(event, true);
        }
        else if (posX < denominator) {
          setPos(uncheckedPos);
          onChange(event, false);
        }
        else
        {
          setPos(undefinedPos);
          onChange(event, null);
        }
    
        setDragging(false);
        setOutline(false);
      }
    
      const onMouseDown = (event: any) => {
        event.preventDefault();
        // Ignore right click and scroll
        if (typeof event.button === "number" && event.button !== 0) {
          return;
        }
    
        onDragStart(event.clientX);
        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("mouseup", onMouseUp);
      }
    
      const onMouseMove = (event: any) =>  {
        event.preventDefault();
        onDrag(event.clientX);
      }
    
      const onMouseUp = (event: any) =>  {
        onDragStop(event);
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", onMouseUp);
      }
    
      const onTouchStart = (event: any) =>  {
        onDragStart(event.touches[0].clientX);
      }
    
      const onTouchMove = (event: any) =>  {
        onDrag(event.touches[0].clientX);
      }
    
      const onTouchEnd = (event: any) =>  {
        event.preventDefault();
        onDragStop(event);
      }
    
      const setHasOutline = () => {
        setOutline(true);
      }
    
      const unsetHasOutline = () => {
        setOutline(false);
      }
    
      const onClick = (event: any) => {
        event.preventDefault();
        switchRef.current.focus();
        
        setOutline(false);

        let bounds = switchRef.current.getBoundingClientRect();
        let posX = event.clientX - bounds.left;

        var denominator = bounds.width / ((props.nullable === true) ? 3 : 2);
        if ((props.nullable === true && posX > 2 * denominator)
        || (props.nullable !== true && posX >= denominator)) {
          setPos(checkedPos);
          onChange(event, true);
        }
        else if (posX < denominator) {
          setPos(uncheckedPos);
          onChange(event, false);
        }
        else
        {
          setPos(undefinedPos);
          onChange(event, null);
        }
      }
    
      const onChange = (event: any, checked?: boolean | null) => {
        if (props.onChange && props.checked !== checked)
        {
          props.onChange(checked, event, props.id);
        }
      }

      const handleStyle : CSSProperties = {
        height: handleDiameter,
        width: handleDiameter,
        background: getBackgroundColor(
          pos,
          checkedPos,
          uncheckedPos,
          props.offHandleColor,
          props.onHandleColor
        ),
        display: "inline-block",
        cursor: props.disabled ? "default" : "pointer",
        borderRadius: "50%",
        position: "absolute",
        transform: `translateX(${pos}px)`,
        top: Math.max(0, (height - handleDiameter) / 2),
        outline: 0,
        boxShadow: hasOutline ? props.activeBoxShadow : props.boxShadow,
        border: 0,
        WebkitTransition: isDragging ? undefined : "background-color 0.25s, transform 0.25s, box-shadow 0.15s",
        MozTransition: isDragging ? undefined : "background-color 0.25s, transform 0.25s, box-shadow 0.15s",
        transition: isDragging ? undefined : "background-color 0.25s, transform 0.25s, box-shadow 0.15s"
      };

      const backgroundStyle : CSSProperties  = {
        height,
        width,
        maxHeight: height,
        minHeight: height,
        maxWidth: width,
        minWidth: width,
        position: "relative",
        background: getBackgroundColor(
          pos,
          checkedPos,
          uncheckedPos,
          props.offColor,
          props.onColor,
          props.midColor
        ),
        borderRadius: height / 2,
        cursor: props.disabled ? "default" : "pointer",
        WebkitTransition: isDragging ? undefined : "background 0.25s",
        MozTransition: isDragging ? undefined : "background 0.25s",
        transition: isDragging ? undefined : "background 0.25s"
      };

      const backgroundContentStyle : CSSProperties  = {
        opacity : (isDragging ? (Math.abs((pos - uncheckedPos) / (checkedPos - uncheckedPos) - 0.5) * 2) : (props.nullable && props.checked == null) ? 0 : 1),
        WebkitTransition: isDragging ? undefined : "background 0.25s",
        MozTransition: isDragging ? undefined : "background 0.25s",
        transition: isDragging ? undefined : "background 0.25s"
      };

    return (
    <div className={makeClassName("switch", props)} ref={switchRef} onFocus={setHasOutline} onBlur={unsetHasOutline}
      style={backgroundStyle}>
        <div className={"switch-content-background"} style={backgroundContentStyle} onClick={props.disabled ? undefined : onClick} onMouseDown={e => e.preventDefault()}>
          {props.children}
        </div>
        <div
          className="switch-handle"
          style={handleStyle}
          onClick={e => e.preventDefault()}
          onMouseDown={props.disabled ? undefined : onMouseDown}
          onTouchStart={props.disabled ? undefined : onTouchStart}
          onTouchMove={props.disabled ? undefined : onTouchMove}
          onTouchEnd={props.disabled ? undefined : onTouchEnd}
          onTouchCancel={props.disabled ? undefined : unsetHasOutline}
        />
      </div>
    );
};

export default DeepSwitch;
