import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { loginModule, watcherModule, toastModule } from "config/modulesConf";
import Navbar from "./navbar/Navbar";
import { ISmartState, ISmartAction } from "interfaces/ISmart";
import { smartConnect } from "app/appUtils";
import "./pageWrapper.scss";
import { IRoute } from "interfaces/IRoute";
import Toast, { IToast } from "components/common/toast/Toast";
import { IErrorsData } from "interfaces/IErrors";
import Footer from "./footer/Footer";
import { userSettingsModule } from "config/modulesConf";
import { getUserSettingsApi } from "config/apiConf";
import { IUserSettingsData } from "interfaces/modules/IUserSettings";
import { changeLanguage } from "services/translationService";
import { noAccessRoute } from "config/routeConf";
import { IAzureADFunctionProps } from "react-aad-msal";

interface IFinalRoute {
  route: IRoute;
  container?: any;
  noLoginContainer?: any;
}

interface IProps {
  finalRoutes: any;
  setLoader: any;
  login?: ISmartState;
  loginActions?: ISmartAction;
  watcher?: ISmartState;
  userSettings?: ISmartState;
  userSettingsActions?: ISmartAction;
  toast?: ISmartState;
  toastActions?: ISmartAction;
  azureADFunction? : IAzureADFunctionProps;
}

const PageWrapper = (props: IProps) => {
  const [toast, setToast] = useState<IToast>();
  const userSettingsData: IUserSettingsData = props.userSettings?.data;
  const [pTimer, setPtimer] = useState() as any;
  const [cont, setCont] = useState(false);

  useEffect(() => {
    setPtimer(setTimeout(props.setLoader, 1000, true));
    props.userSettingsActions?.httpCall(getUserSettingsApi);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.userSettings?.opCompleted) {
      clearTimeout(pTimer);
      changeLanguage(userSettingsData.language);
      props.setLoader(false);
      setCont(true);
    }
    // eslint-disable-next-line
  }, [props.userSettings?.opCompleted]);

  useEffect(() => {
    if (props.toast?.opCompleted) {
      const toast: IToast = props.toast.data;
      setToast(toast);
    }
    // eslint-disable-next-line
  }, [props.toast?.opCompleted]);

  useEffect(() => {
    if (props.toast?.errors) {
      const toast: IToast = props.toast.data;
      setToast(toast);
    }
    // eslint-disable-next-line
  }, [props.toast?.errors]);

  useEffect(() => {
    if (props.watcher?.errors) {
      const errorsData: IErrorsData = props.watcher.errors.data;
      if (errorsData.errors) 
      {
        const toast: IToast = { label: errorsData.errors[0],type: "error" }
        setToast(toast);
      }
    }
    // eslint-disable-next-line
  }, [props.watcher?.errors]);

  const closeToast = () => {
    props.toastActions?.reset();
    setToast(undefined);
  }

  if (!cont) return null;

  const buildRoutes = (finalRoute: IFinalRoute, index: number) => {
    if (!props.userSettings?.opCompleted) return null;
    const route = finalRoute.route;
    const params = route.pathParams;
    const path = params ? `${route.path}${route.pathParams}` : route.path;
    const exact = route.exact ? route.exact : true;
    let redirectTo = route.redirectTo !== undefined ? route.redirectTo : null;
    let finalContainer = <finalRoute.container route={route} />;
    
    if (route.roleRequired) {
      const hasPermission = route.roleRequired.filter((element: any) => element === userSettingsData.role)[0]
      if (!hasPermission) redirectTo = noAccessRoute;
    }

    return (
      <Route exact={exact} key={index} path={path}>
        {finalContainer}
        {redirectTo ? <Redirect to={redirectTo.path} /> : null}
      </Route>
    );
  };

  return (
    <div className="pageWrapper">
      <div className="pageContainer">

        <Navbar azureADFunction={props.azureADFunction}/>
        <label>{props.azureADFunction?.accountInfo?.account.name}</label>
        <Switch>{props.finalRoutes.map(buildRoutes)}</Switch>
        <Footer />
      </div>
      {toast && <Toast closeParam={null} close={closeToast} data={toast}/>}
    </div>
  );
};

export default smartConnect([watcherModule, toastModule ,loginModule, userSettingsModule], [toastModule, loginModule, userSettingsModule])(PageWrapper);
