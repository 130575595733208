import * as lngConf from "config/lngConf";
import { IValue } from "interfaces/common/IValue";

export const INPUT_STR_MIN_LEN_2 = 2;
export const INPUT_STR_MAX_LEN_8 = 8;
export const INPUT_STR_MAX_LEN_10 = 10;
export const INPUT_STR_MAX_LEN_20 = 20;
export const INPUT_STR_MAX_LEN_50 = 50;
export const INPUT_STR_MAX_LEN_200 = 200;
export const INPUT_STR_MAX_LEN_250 = 250;
export const INPUT_STR_MAX_LEN_255 = 255;
export const INPUT_STR_MAX_LEN_320 = 320;

export const ACCEPTED_IMPORT_FILE = ".csv";
export const FILE_VALIDATION = ["vnd.ms-excel"];

export const INPUT_TYPE_NUMBER = "number";

export const APP_ID = "BAT_SCHEDULER";
export let APi_VERSION = "";
export const IS_DEVELOPMENT = process.env.NODE_ENV === "development" ? true : false;
export const ENVIRONMENT = process.env.NODE_ENV;
if (IS_DEVELOPMENT) APi_VERSION = "";

export let USE_AZURE = true;
if (IS_DEVELOPMENT) USE_AZURE = true;

export const DEV_TOKEN =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IkN0VHVoTUptRDVNN0RMZHpEMnYyeDNRS1NSWSIsImtpZCI6IkN0VHVoTUptRDVNN0RMZHpEMnYyeDNRS1NSWSJ9.eyJhdWQiOiJjYmYwYTRkZC1iOTA1LTRiNzctOWYwOC1hMmYyMGU3NDQwYWYiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC84ZjQ3NGUxMi0xYjBjLTQ2M2QtYTAwNC1lZGJjMmUyYzcyYjAvIiwiaWF0IjoxNTkxMTg5MjM5LCJuYmYiOjE1OTExODkyMzksImV4cCI6MTU5MTE5MzEzOSwiYWNyIjoiMSIsImFpbyI6IkFVUUF1LzhQQUFBQUhCbmE0MUU5SW45bWxhNzdqQTI2NmVrMGppejZhSlhMcmxZbGpmaUpqNGFOR2YvQnA4K2V3MWJPZnl5emM0SS9oVXVyWWhnZjR1dzJ2VURzK000WkJRPT0iLCJhbXIiOlsicHdkIl0sImFwcGlkIjoiNGRiNjk0NDItM2JjMy00YjdkLTg0MjMtNmYxOTA3ZmU3ZjhmIiwiYXBwaWRhY3IiOiIwIiwiZW1haWwiOiJFWFRfTFNUNzhAb3Blbi1ncm91cGUuY29tIiwiaWRwIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvYjhjNmJhNDgtYmM1Ni00MTM5LWIyNjctNWExN2YyODdiZWIxLyIsImlwYWRkciI6IjE3OC4xMzguMzUuMTc3IiwibmFtZSI6IlNUQU5FU0NVIExhdXJlbnRpdSIsIm9pZCI6IjkzMGU2ODA3LWVkMzQtNDNkMy05MDExLTRkMDIyN2E1ZTEzYSIsInNjcCI6IkRhdGEuUmVhZCIsInN1YiI6InRGSWdjUlA0UktxblFyTS1OQjRyUk1RRk1OZ1V3a1VqWDNOWEkxZm1TQXciLCJ0aWQiOiI4ZjQ3NGUxMi0xYjBjLTQ2M2QtYTAwNC1lZGJjMmUyYzcyYjAiLCJ1bmlxdWVfbmFtZSI6IkVYVF9MU1Q3OEBvcGVuLWdyb3VwZS5jb20iLCJ1dGkiOiJXOGY4Mk96c0RFT0puMTRnUDBqQkFBIiwidmVyIjoiMS4wIn0.Ix8_7n-NchsGvBJ90BPfsu0fZAhOg1rXIn4N0BScjtSZs4x3JDKTLf78PkwJNlj2T77Ufa0qOx5POPdig4ZKP_1GUMQlY_C7T_M1kuT9ozxvcEC88z8b-X9j9rETJZXvxaHfT3iBZxaXi23gPWyM21HY59SwO-Z5ulrZ3nsPZaTqlUm-y0utXaIZ-EfR3e_ZUULUz1E8CeyyUuaJd-jeLf8ICqJUD_Tvvp0t94EvDHhcaE6j5U6HWJsTAnb3Wy6fPBW2-nFrLHJSBVkC_T2eg_NVoEqDGHHG7OYY3lk47Yo_tWFVKnipyq99F8AU5omq0j92iWjyUEF-AQYRvE2bEQ";

export const TABLE_ITEMS_PER_PAGE = 10;
export const TABLE_EXPAND_OFFSET = 15;
export const TIMEOUT_400 = 400;
export const MORE_VISIBLE_ITEMS = 2;

export const BUTTON_TYPE_SUBMIT = "submit";

export const DROPDOWN_NAME_KEY = "name";
export const DROPDOWN_ID_KEY = "id";

export const tablePagesDropDown = (t: any) => [
  { value: "", label: t(lngConf.DP_ALL) },
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 25, label: 25 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
];

export const dateFormats = () => [
  {
    id: "en",
    dateFormat: "MM/dd/yyyy",
    momentDateFormat: "MM/DD/yyyy",
  },
  {
    id: "fr",
    dateFormat: "dd/MM/yyyy",
    momentDateFormat: "DD/MM/yyyy",
  },
];

export const timeTable : IValue[] = [
  {
    id: "1",
    name: "9h00-13h00 \n 15h00-18h00",
    object: ["9:00:00", "13:00:00", "15:00:00", "18:00:00"]
  },
  {
    id: "2",
    name: "10h00-13h30 \n 15h30-19h00",
    object: ["10:00:00", "13:30:00", "15:30:00", "19:00:00"]
  },
];