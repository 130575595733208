import React, { useEffect, useState } from "react";
import { makeClassName } from "utils/componentUtils";
import { IDefaultProps } from "interfaces/IDefaultProps";
import "./pagination.scss";
import Button from "components/common/button/Button";
import Row from "components/common/row/Row";
import Dropdown from "components/common/dropdown/Dropdown";
import Input from "components/common/input/Input";
import { useTranslate } from "services/translationService";
import * as lngConf from "config/lngConf";
import { searchArrayIndex } from "utils/arrayUtils";

interface IProps extends IDefaultProps {
  crrPage: number;
  onChangeCurrentPage: any;
  maxPages: number;
  pages: any;
  itemsPerPage: number;
  onChangeItemsPerPage: any;
  totalEntries?: number;
}

const Pagination = (props: IProps) => {
  const { t } = useTranslate();
  const [itemsPerPageIndex, setitemsPerPageIndex] = useState(0);
  const [value, setValue] = useState(1);

  useEffect(() => {
    setitemsPerPageIndex(props.itemsPerPage ? searchArrayIndex(props.pages, "label", props.itemsPerPage) : 0);
    // eslint-disable-next-line
  }, [props.itemsPerPage]);

  const changeValue = (val: any) => {
    const newVal = parseInt(val);
    setValue(newVal);
    props.onChangeCurrentPage(newVal);
  };

  useEffect(() => {
    if (props.crrPage !== undefined) {
      setValue(props.crrPage);
    }
  }, [props.crrPage]);

  return (
    <div className={makeClassName("pagination", props)}>
      <Button styleClass="pagination" param={props.crrPage - 1} onClick={props.onChangeCurrentPage} label="Précédent" />
      <Row styleClass="center">
        <Row styleClass="pag">
          <span>{t(lngConf.PAG_PAGE)}</span>
          <Input
            type="number"
            inputProps={{ min: 1, max: props.maxPages }}
            onChange={(val: any) => setValue(parseInt(val))}
            onEnter={changeValue}
            value={value}
          />
          <span>
            {t(lngConf.PAG_OF)} {props.maxPages}
            {props.totalEntries !== undefined && ` (${props.totalEntries})`}
          </span>
        </Row>
        <Dropdown
          placeholder={t(lngConf.PH_SELECT)}
          onChange={props.onChangeItemsPerPage}
          value={props.pages[itemsPerPageIndex]}
          filter={false}
          deletable={false}
          options={props.pages}
          noSort={true}
        />
      </Row>
      <Button styleClass="pagination" param={props.crrPage + 1} onClick={props.onChangeCurrentPage} label="Suivant" />
    </div>
  );
};

export default Pagination;
