import React, {  } from "react";
import { IRoute } from "interfaces/IRoute";
import PageContent from "pageWrapper/pageContent/PageContent";
import { ISmartAction, ISmartState } from "interfaces/ISmart";
//import {  } from "config/modulesConf";

interface IProps {
    route: IRoute;
    page3: ISmartState;
    page3Actions: ISmartAction;
}

const AllAnimationsCalendar = (props: IProps) => {
    return (
    <PageContent loader={false}>
        {"Calendar"}
    </PageContent>
    )
}

export default AllAnimationsCalendar; //smartConnect([], [])(AllAnimationsCalendar);