import React from "react";
import "./label.scss";
import { IDefaultProps } from "interfaces/IDefaultProps";

interface IProps extends IDefaultProps {
  name?: any;
  bold?: boolean;
  labelSize?: string
}

const Label = (props: IProps) => {
  return (
      <label className={(props.bold === true ? "bold" : "none") + (props.labelSize !== undefined ? " " + props.labelSize : "")}>
        {props.name}
      </label>
  );
};

export default Label;
