import { IHttpRequest } from "interfaces/IHttpRequest";
import { httpMethodTypes } from "services/http/httpConf";

export const loginApi: IHttpRequest = {
  name: "loginApi",
  method: httpMethodTypes.POST,
  endPoint: "/api/user/login",
};

export const getUserSettingsApi: IHttpRequest = {
  name: "getUserSettingsApi",
  method: httpMethodTypes.GET,
  endPoint: "/api/user/settings",
};

//Users
export const getAllUsersApi: IHttpRequest = {
  name: "getAllUsersApi",
  method: httpMethodTypes.GET,
  endPoint: "/api/Users",
};

export const getUserSectors1Api: IHttpRequest = {
  name: "getUserSectors1Api",
  method: httpMethodTypes.GET,
  endPoint: "/api/Users/:userId/Sectors1",
};

export const updateUserSectors1Api: IHttpRequest = {
  name: "updateUserSectors1Api",
  method: httpMethodTypes.PUT,
  endPoint: "/api/Users/:userId/Sectors1",
};


//Sectors1
export const getAllSectors1Api: IHttpRequest = {
  name: "getAllSectors1Api",
  method: httpMethodTypes.GET,
  endPoint: "/api/Sectors1",
};

//Sectors3
export const getSectors3DropdownApi: IHttpRequest = {
  name: "getSectors3DropdownApi",
  method: httpMethodTypes.GET,
  endPoint: "/api/Sectors3/dropdown",
};

//Statistics
export const getSector3StatisticsApi: IHttpRequest = {
  name: "getSector3StatisticsApi",
  method: httpMethodTypes.POST,
  endPoint: "/api/Statistics/Sectors3",
};

//Cancellation Request
export const createCancellationRequestApi: IHttpRequest = {
  name: "createCancellationRequestApi",
  method: httpMethodTypes.POST,
  endPoint: "/api/CancellationRequests",
};

export const deleteCancellationRequestApi: IHttpRequest = {
  name: "deleteCancellationRequestApi",
  method: httpMethodTypes.DELETE,
  endPoint: "/api/CancellationRequests",
};


//Animations
export const getAnimationApi: IHttpRequest = {
  name: "getAnimationApi",
  method: httpMethodTypes.GET,
  endPoint: "/api/animation/:id",
};

export const createAnimationApi: IHttpRequest = {
  name: "createAnimationApi",
  method: httpMethodTypes.POST,
  endPoint: "/api/missions",
};

export const getAllAnimationsApi: IHttpRequest = {
  name: "getAllAnimationsApi",
  method: httpMethodTypes.GET,
  endPoint: "/api/missions",
};

export const getBookingStatisticsApi: IHttpRequest = {
  name: "getBookingStatisticsApi",
  method: httpMethodTypes.GET,
  endPoint: "/api/missions/bookingStatistics",
};

//Intervention Points
export const getAllInterventionPointsApi: IHttpRequest = {
  name: "getAllInterventionPointsApi",
  method: httpMethodTypes.GET,
  endPoint: "/api/interventionPoints",
};

export const getInterventionPointApi: IHttpRequest = {
  name: "getInterventionPointApi",
  method: httpMethodTypes.GET,
  endPoint: "/api/interventionPoints/:id",
};

export const getInterventionPointAvailabilityApi: IHttpRequest = {
  name: "getInterventionPointAvailabilityApi",
  method: httpMethodTypes.GET,
  endPoint: "/api/InterventionPointAvailability",
};

export const getInterventionPointByAvailabilityApi: IHttpRequest = {
  name: "getInterventionPointByAvailabilityApi",
  method: httpMethodTypes.GET,
  endPoint: "/api/InterventionPointAvailability/NextAvailability",
};

export const getInterventionPointPromotersAvailableApi: IHttpRequest = {
  name: "getInterventionPointPromotersAvailableApi",
  method: httpMethodTypes.GET,
  endPoint: "/api/InterventionPointAvailability/Promoters",
};

export const updateInterventionPointApi: IHttpRequest = {
  name: "updateInterventionPointApi",
  method: httpMethodTypes.PUT,
  endPoint: "/api/interventionPoints/:id",
};

export const createInvitationApi: IHttpRequest = {
  name: "createInvitationApi",
  method: httpMethodTypes.POST,
  endPoint: "/api/user/createinvitation",
};

export const getRolesDropdownApi: IHttpRequest = {
  name: "getRolesDropdownApi",
  method: httpMethodTypes.GET,
  endPoint: "/api/roles/dropdown",
}

export const getCampaignDropdownApi: IHttpRequest = {
  name: "getCampaignDropdownApi",
  method: httpMethodTypes.GET,
  endPoint: "/api/campaigns/dropdown",
}

export const updateUserApi: IHttpRequest = {
  name: "updateUserApi",
  method: httpMethodTypes.PUT,
  endPoint: "/api/user/:id",
};

export const importPromoterInterventionPointsApi: IHttpRequest = {
  name: "importPromoterInterventionPointsApi",
  method: httpMethodTypes.POST,
  endPoint: "/api/promoterinterventionpoints/import",
};