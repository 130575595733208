import React from "react";
import "./neuroButton.scss";
import Button from "components/common/button/Button";
import { IButtonProps } from "components/common/button/IButtonProps";
//import { FaPlusCircle } from "react-icons/fa";

interface IProps extends IButtonProps {
  color?: string;
  rounded?: boolean;
  size?: string;
}

const NeuroButton = (props: IProps) => {
  return (
    <Button
      styleClass={"neuroButton" + (props.rounded === true ? " rounded" : "") + (props.color !== undefined ? ` neurobtn--${props.color}` : "") +  (props.size !== undefined ? ` size--${props.size}` : "")}
      //content={<FaPlusCircle />}
      tooltip={props.tooltip ? props.tooltip : ""}
      {...props}
    />
  );
};

export default NeuroButton;
