import React, { useState } from "react";
import moment from "moment";

import { smartConnect } from "app/appUtils";
import { interventionPointsNextAvailabilitiesModule } from "config/modulesConf";
import { IRoute } from "interfaces/IRoute";
import { nameOf } from "utils/nameOfUtils";

import DateSelector from "components/common/dateSelector/DateSelector";
import Label from "components/common/label/Label";
import Row from "components/common/row/Row";
import Tabs from "components/common/tabs/Tabs";
import { ITabItem } from "components/common/tabs/ITabs";
import { IAllAnimationsFilters } from "containers/animations/IAnimations";
import PageContent from "pageWrapper/pageContent/PageContent";
import InterventionPointsNextAvailabilitiesList from "./tabs/InterventionPointsNextAvailabilitiesList";

interface IProps {
  route: IRoute;
  dateFrom: Date;
  dateTo: Date;
}

const InterventionPointsNextAvailabilities = (props: IProps) => {
  const [dateFrom, setDateFrom] = useState<Date>(moment().startOf('isoWeek').add(1, 'week').toDate());
  const [dateTo, setDateTo] = useState<Date>(moment().startOf('isoWeek').add(2, 'week').toDate());

  return (
    <PageContent 
      tabsMode={true}
    >
      <Row justifyContent='center'>
        <Label name="Du" bold={true} labelSize="normal" />
        <DateSelector
          name={nameOf((n: IAllAnimationsFilters) => n.dateFrom)}
          onChange={(filter: Date) => setDateFrom(filter)}
          dateFormat={"dd/MM/yyyy"}
          value={dateFrom}
        />
        <Label name="Au" bold={true} labelSize="normal" />
        <DateSelector fullSize={true}
          name={nameOf((n: IAllAnimationsFilters) => n.dateTo)}
          onChange={(filter: Date) => setDateTo(filter)}
          dateFormat={"dd/MM/yyyy"}
          value={dateTo}
        />
      </Row>
      <Tabs
        showTabs={false}
        items={[
          {
            label: "Liste",
            content: InterventionPointsNextAvailabilitiesList,
            path: "liste",
            props: {
              dateFrom,
              dateTo,
            }
          } as ITabItem,
        ]}
      />
    </PageContent>
  );
};

export default smartConnect(
  [interventionPointsNextAvailabilitiesModule],
  [interventionPointsNextAvailabilitiesModule]
)(InterventionPointsNextAvailabilities);
